import React from "react";
import DashboardIcon from '@mui/icons-material/Dashboard';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';

export const SidebarData = [
  {
    id:1,
    title: "Dashboard",
    path: "/Dashboard",
    icon: DashboardIcon,
  },
  {
    id:4,
    title: "Players",
    path: "/Players",
    icon: DirectionsRunIcon,
  },
];
