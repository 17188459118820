import * as React from 'react';
import { useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './Players.css';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';
import Stack from '@mui/material/Stack';
import { useNavigate } from "react-router-dom";
import TablePagination from '@mui/material/TablePagination';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { deletePlayer, getCollegeList, getPlayersList, getTeamsList,getLeagueList } from '../../../Utils/services';
import CustomButton from '../../../Shared/Button/button';
import { BUTTON_COLOR_VARIANTS } from '../../../Utils/constants';
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchBar from '../../../Shared/SearchBar/searchBar';
import Avatar from '@mui/material/Avatar';
import { getInitialsForName, getRandomColorCode } from '../../../Utils/utils';
import Loader from '../../../Shared/Progress/loader';
import { Badge, Popover } from '@mui/material';
import CustomSelect from '../../../Shared/Select/customSelect';
import { rounds } from './CreatePlayers/data/createPlayerCreationData';
import { isObjectEmpty } from '../../../Utils/utils';
import { isObjecthasEmptyValues } from '../../../Utils/utils';
import CustomModal from '../../Modals/customModal';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const Players = () => {
  const Navigate = useNavigate();
  const [players, setPlayers] = useState([]);
  const [page, setPage] = useState(0);
  const [todisplaypage, settodisplaypage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [Totalrecord, setTotalrecord] = useState();
  const [dropdownitemtotalpages, setdropdownitemtotalpages] = useState([]);
  const [loadSpinner, setLoadSpinner] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [selectedRound, setSelectedRound] = useState('');
  const [teams, setTeams] = useState([])
  const [selectedTeam, setSelectedTeam] = useState('');
  const [colleges, setColleges] = useState([])
  const [selectedCollege, setSelectedCollege] = useState('');
  const [anchorElFilters, setAnchorElFilters] = useState(null);
  const [isFiltersApplied, setFiltersApplied] = useState(true);
  const [playerSelectedToDelete, setPlayerSelectedToDelete] = useState('');
  const [showPlayerDeleteModal, setShowPlayerDeleteModal] = useState(false);

  const toggleDeleteModal = () => setShowPlayerDeleteModal(!showPlayerDeleteModal)
  const [anchorElLeague, setAnchorElLeague] = useState(null);
  const [selectedLeague, setSelectedLeague] = useState('');
  const [selectedLeagueId, setSelectedLeagueId] = useState('1');
  const [leagues, setLeagues] = useState([]);
  const [leagueMenuAnchor, setLeagueMenuAnchor] = useState(null);

  useEffect(() => {
    getPlayersPerPage(rowsPerPage, searchValue,selectedTeam, selectedRound, selectedCollege, selectedLeagueId)
    getCollegevalues()
    getTeamvalues() // eslint-disable-next-line react-hooks/exhaustive-deps
    getLeaguevalues()
  }, [page,rowsPerPage, searchValue, selectedTeam, selectedRound, selectedCollege, selectedLeagueId]);

  // setPage by default start with 0 hence we add +1 to settodisplaypage to display the dropdown
  const handleChangeRow = (event, newPage) => {
    setPage(event.target.value - 1);
    settodisplaypage(event.target.value);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    settodisplaypage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    settodisplaypage(1);
    setRowsPerPage(event.target.value);
    getPlayersPerPage(event.target.value, searchValue)
  };

  // async function getPlayersPerPage(rowsPerPagedata, searchKey) {
  //   let samplearray = []
  //   let params = {
  //     page: page + 1,
  //     count: rowsPerPagedata,
  //     player_name: searchKey,
  //     team_name: selectedTeam,
  //     college: selectedCollege,
  //     round: selectedRound,
  //     league: selectedLeagueId,
  //   };
  async function getPlayersPerPage(rowsPerPagedata, searchKey, team, round, college, leagueId) {
  let samplearray = [];
  let params = {
    page: page + 1,
    count: rowsPerPagedata,
    player_name: searchKey,
    team_name: team,
    college: college,
    round: round,
    league: leagueId,
  };
    console.log("Params being sent:", params);
    await getPlayersList(params).then((response) => {
      setLoadSpinner(false)
      setPlayers(response.body.player_info);
      setTotalrecord(response.body.page_info.total_number_of_players);
      let totalpage = response.body.page_info.number_of_pages;
      for (let i = 1; i <= (totalpage + 1); i++) {
        samplearray.push(i)
        setdropdownitemtotalpages(samplearray);
      }
      closeFilters()
    })
  }

  async function getPlayersPerPageWithFilters(rowsPerPagedata, searchKey, team, round, college, leagueId) {
    let samplearray = []
    let params = {
      page: page + 1,
      count: rowsPerPagedata,
      player_name: searchKey,
      team_name: team,
      college: college,
      round: round,
      league: leagueId
    }
    await getPlayersList(params).then((response) => {
      setLoadSpinner(false)
      setPlayers(response.body.player_info);
      setTotalrecord(response.body.page_info.total_number_of_players);
      let totalpage = response.body.page_info.number_of_pages;
      for (let i = 1; i <= (totalpage + 1); i++) {
        samplearray.push(i)
        setdropdownitemtotalpages(samplearray);
      }
      closeFilters()
      hasFiltersApplied({ round: round, team_name: team, college: college })
    })
  }

  const onPlayerSearch = (e) => {
    setSearchValue(e.target.value)
    if (e.target.value === "" || e.target.value === null || e.target.value === undefined) {
      getPlayersPerPage(rowsPerPage)
    }
  }

  function CratePlayer() {
    Navigate('/Players/player');
  }

  function viewPlayer(value) {
    let playerid = value.player_id;
    Navigate('/Players/viewPlayer/' + playerid);
  }

  function editPlayer(data) {
    let playerid = data.player_id;
    Navigate('/Players/player/' + playerid);
  }

  const onRoundSelection = (value) => setSelectedRound(value)
  const onTeamSelection = (value) => setSelectedTeam(value)
  const onCollegeSelection = (value) => setSelectedCollege(value)


  const onSearchKeyDown = (e) => {
    if (e.key === 'Enter') {
      getPlayersPerPage(rowsPerPage, searchValue)
      e.preventDefault();
    }
  }

  const handleFilters = (e) => {
    e.preventDefault();
    setAnchorElFilters(e.currentTarget)
  }

  const closeFilters = () => setAnchorElFilters(null)

  const getFiltersPopoverId = () => {
    return Boolean(anchorElFilters) ? 'filter-popover' : undefined
  }
  const handleLeague = (e) => {
    e.preventDefault();
    setAnchorElLeague(e.currentTarget);
  }
  
  const closeLeague = () => setAnchorElLeague(null);
  
  const getLeaguePopoverId = () => {
    return Boolean(anchorElLeague) ? 'league-popover' : undefined
  }
  
  const onLeagueSelection = (value, id) => {
    setSelectedLeague(value);   // Update selected league name
    setSelectedLeagueId(id);    // Update selected league id
    getPlayersPerPage(rowsPerPage, searchValue, selectedTeam, selectedRound, selectedCollege, id); // Call function with league id
    console.log("Selected league ID:", id);
    closeLeague();              // Close league selection popover
  };

  async function getTeamvalues() {
    await getTeamsList().then((res) => {
      console.log(res.body);
      setTeams(getTeamsListForAutocomplete(res.body))
    }).catch(() => setLoadSpinner(false))
  }
  async function getCollegevalues() {
    await getCollegeList().then((res) => {
      setColleges(getCollegeListForAutocomplete(res.body))
    }).catch(() => setLoadSpinner(false))
  }
  async function getLeaguevalues() {
    await getLeagueList().then((res) => {
      console.log('Leagues fetched:', res.body);  // Debug log
      setLeagues(getLeagueListForAutocomplete(res.body))
    }).catch(() => setLoadSpinner(false))
  }

  function getCollegeListForAutocomplete(list) {
    let newList = [];
    list.forEach(element => {
      let field = { name: element.college_name, value: element.college_name }
      newList.push(field)
    });
    return newList
  }

  function getTeamsListForAutocomplete(list) {
    let newList = [];
    list.forEach(element => {
      let field = { name: element.name, value: element.name, icon: element.logo }
      newList.push(field)
    });
    return newList
  }
  function getLeagueListForAutocomplete(list) {
    let newList = [];
    list.forEach(element => {
      let field = { name: element.sports_name, value: element.sports_name, id:element.id}
      newList.push(field)
    });
    console.log('Formatted Leagues:', newList);  // Debug log
    return newList
  }
  const closeLeagueMenu = () => {
    setLeagueMenuAnchor(null);
  };
  function clearFilter() {
    setSelectedRound(''); setSelectedCollege(''); setSelectedTeam('')
    getPlayersPerPageWithFilters(rowsPerPage, searchValue, '', '', '')
  }

  function applyFilter() {
    getPlayersPerPageWithFilters(rowsPerPage, searchValue, selectedTeam, selectedRound, selectedCollege)
  }

  const hasFiltersApplied = (obj) => {
    setFiltersApplied(!isObjectEmpty(obj) && isObjecthasEmptyValues(obj))
  }

  function onPlayerDelete(player) {
    toggleDeleteModal()
    setPlayerSelectedToDelete(player)
  }
  


  async function deletePlayerRecord(player) {
    let formData = new FormData();
    formData.append('is_deleted', 1);
    formData.append('player_id', player.player_id);
    await deletePlayer(formData).then((res) => {
      var filteredPlayers = players.filter((playerItem) => playerItem.player_id !== player.player_id)
      setPlayers(filteredPlayers);
      toggleDeleteModal()
    })
  }


  return (
    <>
      <CustomModal showModal={showPlayerDeleteModal} updateModalState={toggleDeleteModal} message="Are you sure you want to delete the player record ?" onClick={() => deletePlayerRecord(playerSelectedToDelete)} isCancel={true} />
      <div className={"playerContainer"}>
        <Loader show={loadSpinner} />
        <div className="playersDiv">
          <Grid className="topDivDesign" container spacing={2}>
            <Grid item sm={8} md={8} xs={7} xl={7} className='textLeft paddingTopBottom'>
              <SearchBar searchKey={searchValue} setSearchKey={onPlayerSearch} onKeyDown={onSearchKeyDown} placeholder={"Type and press enter key to search for players"} />
            </Grid>
            <Grid sm={4} md={4} xs={5} xl={5} item className="paddingTopBottom">
              <Stack gap={1} direction="row">
                <Badge color="success" variant="dot" invisible={selectedLeague === ''}>
                  <CustomButton id={getLeaguePopoverId} variant="contained" onClick={handleLeague} title={"League"} icon={<ArrowDropDownIcon />} colorVariant={BUTTON_COLOR_VARIANTS.ORANGE} />
                </Badge>
                <Popover
                  id={getLeaguePopoverId()}
                  open={Boolean(anchorElLeague)}
                  anchorEl={anchorElLeague}
                  onClose={closeLeague}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
                  transformOrigin={{ horizontal: 'center' }}
                  PaperProps={{ style: { width: '10%', padding: '15px', borderRadius: '10px' } }}>
                  <div>
                    <Grid container spacing={2}>
                      {leagues.map((league, index) => (
                        <Grid key={index} item sm={12} xs={12}>
                          <MenuItem onClick={() => onLeagueSelection(league.value, league.id)}>
                            {league.name}
                          </MenuItem>
                          {index !== leagues.length - 1 && <hr style={{ border: '0.5px solid #ccc', margin: '3px 0' }} />}
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                </Popover>
                
                <Badge color="success" variant="dot" invisible={isFiltersApplied}>
                  <CustomButton id={getFiltersPopoverId} variant="contained" onClick={handleFilters} title={"Filter"} icon={<FilterListIcon />} colorVariant={BUTTON_COLOR_VARIANTS.BLUE} />
                </Badge>

                <Popover
                  id={getFiltersPopoverId}
                  open={Boolean(anchorElFilters)}
                  anchorEl={anchorElFilters}
                  onClose={closeFilters}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
                  transformOrigin={{ horizontal: 'center' }}
                  PaperProps={{
                    style: { width: '30%', padding: '25px', borderRadius: '20px' },
                  }}>
                  <div>
                    <Grid className="topDivDesign" container spacing={3}>
                      <Grid item sm={6}><CustomSelect label={"Round"} selectedVal={selectedRound} options={rounds} onChange={onRoundSelection}/></Grid>
                      <Grid item sm={6}><CustomSelect label={"Teams"} selectedVal={selectedTeam} options={teams} onChange={onTeamSelection}/></Grid>
                      <Grid item sm={12}><CustomSelect label={"Colleges"} selectedVal={selectedCollege} options={colleges} onChange={onCollegeSelection}/></Grid>
                    </Grid>
                    <Grid container spacing={2} marginTop={1} justifyContent={'flex-end'}>
                      <Grid item><CustomButton variant="outlined" title={"Clear"} onClick={() => clearFilter()}/></Grid>
                      <Grid item><CustomButton variant="contained" title={"Apply"} onClick={() => applyFilter()} colorVariant={BUTTON_COLOR_VARIANTS.BLUE}/></Grid>
                    </Grid>
                  </div>
                </Popover>
                <CustomButton variant="contained" onClick={() => CratePlayer()} title={"Create Player"} icon={<AddIcon />} colorVariant={BUTTON_COLOR_VARIANTS.GREEN} />
              </Stack>
            </Grid>
          </Grid>

          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead className="tableHead">
                <TableRow>
                  <TableCell align="center" className='colorWhite'>Player Name</TableCell>
                  <TableCell align="center" className='colorWhite'>Position</TableCell>
                  <TableCell align="center" className='colorWhite'>College</TableCell>
                  <TableCell align="center" className='colorWhite'>Status</TableCell>
                  <TableCell align="center" className='colorWhite'>Team</TableCell>
                  <TableCell align="center" className='colorWhite'>No. Of Games</TableCell>
                  <TableCell align="center" className='colorWhite'>Grade</TableCell>
                  <TableCell align="center" className='colorWhite'>Draft Price</TableCell>
                  <TableCell align="center" className='colorWhite'>Stock Price</TableCell>
                  <TableCell align="center" className='colorWhite'>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {players.map((item, index) => (
                  <TableRow
                    key={index}>
                    <TableCell align="left">
                      <div className='inlineFlex'>
                        <Avatar sx={{ bgcolor: getRandomColorCode(), width: 30, height: 30, fontSize: 12 }}>{getInitialsForName(item.player_name)}</Avatar>
                        <Typography className='player-typography'>{item.player_name}</Typography >
                      </div>
                    </TableCell>
                    <TableCell align="center"><Typography className='player-typography'>{item.position}</Typography ></TableCell>
                    <TableCell align="center">{item.college}</TableCell>
                    <TableCell align="center">{item.status}</TableCell>
                    <TableCell align="center">{item.team_name}</TableCell>
                    <TableCell align="center">{item.number_of_games}</TableCell>
                    <TableCell align="center">{item.grade}</TableCell>
                    <TableCell align="center">{item.draft_price}</TableCell>
                    <TableCell align="center" className='stock-value-tc'>$ {item.new_stock_value}</TableCell>
                    <TableCell className='player-tc-action' align="center">
                      <Stack direction={'row'}>
                        <VisibilityIcon className="view-player-ic" titleAccess='View' onClick={() => viewPlayer(item)} />
                        <EditIcon className="edit-player-ic" titleAccess='Edit' onClick={() => editPlayer(item)} />
                        <DeleteIcon className='delete-player-ic' titleAccess='Delete' onClick={() => onPlayerDelete(item)} />
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid container justifyContent={'flex-end'} marginTop={1}>
            <Grid item  className={"rowdropdoencol1"}>
              <span>Page</span> &nbsp;&nbsp;
              <FormControl variant="standard" className={'rowdropdoenformcontrol'}>
                <Select id="demo-simple-select" value={todisplaypage} label="Age" onChange={handleChangeRow}>
                  {
                    dropdownitemtotalpages.map((item) => (
                      <MenuItem value={item}>{item}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
              <TablePagination component="div" count={Totalrecord} page={page} onPageChange={handleChangePage} rowsPerPage={rowsPerPage} onRowsPerPageChange={handleChangeRowsPerPage}/>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}

export default Players