export const BASE_API_URL = process.env.REACT_APP_API_KEY; // DEV
export const WEB_DOMAIN = process.env.REACT_APP_WEB_DOMAIN; //Development

export const URL = {
    LOGIN_URL: BASE_API_URL + '/adminLogin',
    FORGOT_PASSWORD : BASE_API_URL + '/sendOtp',
    RESET_PASSWORD : BASE_API_URL + '/forgotPassword',
    PLAYERS_LIST : BASE_API_URL + '/allPlayers',
    UPDATE_DRAFT_PRICE : BASE_API_URL + '/updateDraftPrice',
    ADD_PLAYER_PRICING : BASE_API_URL + '/addPlayerPricing',
    TEAMS_LIST : BASE_API_URL + '/listOfTeams',
    COLLEGE_LIST : BASE_API_URL + '/listOfColleges',
    CREATE_PLAYER : BASE_API_URL + '/addPlayer',
    DELETE_PLAYER : BASE_API_URL + '/updatePlayerStatus',
    UPDATE_PLAYER : BASE_API_URL + '/updatePlayerInfo',
    UPDATE_PLAYER_PERFORMANCE : BASE_API_URL + '/updatePlayerPerformance',
    UPDATE_PLAYER_PERFORMANCE_PRICE : BASE_API_URL + '/updatePerformancePrice',
    VIEW_PLAYER : BASE_API_URL + '/viewPlayer',
    VIEW_PLAYER_PERFORMANCE : BASE_API_URL + '/viewPlayerPerformance',
    LEAGUE_LIST: BASE_API_URL + '/getLeagueList'
}