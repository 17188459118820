import React from "react";
import './Login.css';
import { useEffect, useState } from 'react'
import { Container, Box, Paper, Grid } from '@mui/material';
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { login } from "../../../Utils/services";
import { isObjectEmpty, isObjecthasEmptyValues, isUndefined } from "../../../Utils/utils";
import { navigateToDashboard } from "../../../Utils/navigation";
import Loader from "../../../Shared/Progress/loader";
import CustomTextField from "../../../Shared/TextField/customTextField";
import { validateEmail, validatePassword } from "../../../Utils/validation";
import CustomButton from "../../../Shared/Button/button";
import { BUTTON_COLOR_VARIANTS } from "../../../Utils/constants";
import ForgotPasswordModal from "../../../Shared/Modals/forgotPasswordModal";
import { saveLoginDetails } from "../../../Utils/localStorage";


const Login = () => {
    const navigate = useNavigate();
    const [openFpModal, setFpModal] = useState(false);
    const [loadSpinner, setLoadSpinner] = useState(false);
    const [formData, setFormData] = useState({ email: '', password: '' });
    const [formErrors, setFormErrors] = useState({});

    const toggleForgotPasswordModal = (event , reason) => {
        if (reason && reason === "backdropClick") return;
        setFpModal(!openFpModal)
    };

    useEffect(() => {
        localStorage.removeItem("LoggedInUserName");
    }, []);


    async function handleSubmit(e) {
        e.preventDefault();
        if (isFormValidated(formData)) {
            setLoadSpinner(true)
            let requestBody = { "admin_email": formData.email, "admin_password": formData.password }
            await login(requestBody).then((res) => {
                if (res.statusCode === 200) {
                    saveLoginDetails(res.body)
                    navigateToDashboard(navigate)
                }
                setLoadSpinner(false)
            }).catch(() => setLoadSpinner(false))
        }
    }

    const onTextChange = (previousState, param, newValue) => {
        if (param === 'email') setFormErrors({ ...formErrors, [param]: validateEmail(newValue) })
        if (param === 'password') setFormErrors({ ...formErrors, [param]: validatePassword(newValue) })
        setFormData({ ...previousState, [param]: newValue })
    }

    const isFormValidated = (data) => {
        let errors = {};
        errors.email = validateEmail(!isUndefined(data) ? data.email : '')
        errors.password = validatePassword(!isUndefined(data) ? data.password : '');
        setFormErrors(errors)
        return !isObjectEmpty(errors) && isObjecthasEmptyValues(errors);
    };

    return (
        <Container disbalegutters='true' maxWidth={false} sx={{ bgcolor: '#013369' }}>
            <Box sx={{ height: '50vh' }}>
                <div className="Login-div">
                    <img src="/asset/app-logo-transparent.png" className="Login-logo" alt="logo" />
                </div>
                <Grid>
                    <Paper className="Login-paper">
                        <Loader show={loadSpinner} />
                        <Typography variant='h4' textAlign={'center'} color={'#013369'} fontWeight={600}>Login</Typography>
                        <form onSubmit={handleSubmit} autoComplete="off">
                            <Grid container className="textsLeft" marginTop={"20px"}>
                                <CustomTextField
                                    type={'email'}
                                    value={formData.email}
                                    onChange={(val) => onTextChange(formData, "email", val)}
                                    label={"Email Address"}
                                    variant="standard"
                                    error={Boolean(formErrors.email)}
                                    helperText={formErrors.email}
                                />
                            </Grid>
                            <Grid container className="textsLeft" marginTop={"30px"}>
                                <CustomTextField
                                    type={'password'}
                                    value={formData.password}
                                    onChange={(val) => onTextChange(formData, "password", val)}
                                    label={"Password"}
                                    variant="standard"
                                    error={Boolean(formErrors.password)}
                                    helperText={formErrors.password}
                                />
                            </Grid>
                            <Grid container marginTop={"30px"}>
                                <CustomButton variant="contained" colorVariant={BUTTON_COLOR_VARIANTS.BLUE} type="submit" title="Sign In" />
                            </Grid>
                            <Grid container marginTop={"30px"}>
                            <CustomButton variant="text" onClick={toggleForgotPasswordModal} title="Forgot Password ?" />
                            </Grid>
                        </form>
                        <ForgotPasswordModal isShow={openFpModal} onClose={toggleForgotPasswordModal} title={"Reset Password"} />
                    </Paper>
                </Grid>
            </Box>
        </Container>
    )
}

export default Login