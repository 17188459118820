export const PLAYER_QUARTER_1 = 'Q1'
export const PLAYER_QUARTER_1_2 = 'Q1_Q2'
export const PLAYER_QUARTER_1_3 = 'Q1_Q3'
export const PLAYER_QUARTER_1_4 = 'Q1_Q4'

export const REDUX_ACTIONS = {
    TOGGLE_SIDEBAR : 'toggle_sidebar',
    RESPONSE_HANDLER : 'response_handler',
}

export const BUTTON_COLOR_VARIANTS = {
    GREEN : 'GREEN',
    BLUE : 'BLUE',
    RED : 'RED',
    ORANGE : 'ORANGE'
}

export const APP_BUTTON_COLORS = {
    GREEN : '#099885',
    BLUE : '#1976d2',
    WHITE : '#ffffff',
    ORANGE : '#F5761A'
}