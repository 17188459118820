export const performance_default_values = () => {
    return [
        { id: 1, 'playerStatic': 'Passing Yards', 'param': 'passing_yard', 'QOne': 0.0, 'QOneTwo': 0.0, 'QoneThree': 0.0, 'QoneFour': 0.0 , completedColumns: []},
        { id: 2, 'playerStatic': 'Rushing Yard', 'param': 'rushing_yard', 'QOne': 0.0, 'QOneTwo': 0.0, 'QoneThree': 0.0, 'QoneFour': 0.0 , completedColumns: []},
        { id: 3, 'playerStatic': 'Receiving Yard', 'param': 'receiving_yard', 'QOne': 0.0, 'QOneTwo': 0.0, 'QoneThree': 0.0, 'QoneFour': 0.0 , completedColumns: []},
        { id: 4, 'playerStatic': 'Reception', 'param': 'reception', 'QOne': 0.0, 'QOneTwo': 0.0, 'QoneThree': 0.0, 'QoneFour': 0.0 , completedColumns: []},
        { id: 5, 'playerStatic': '10 Completions', 'param': 'completions_10', 'QOne': 0.0, 'QOneTwo': 0.0, 'QoneThree': 0.0, 'QoneFour': 0.0 , completedColumns: []},
        { id: 6, 'playerStatic': 'TD Pass', 'param': 'td_pass', 'QOne': 0.0, 'QOneTwo': 0.0, 'QoneThree': 0.0, 'QoneFour': 0.0 , completedColumns: []},
        { id: 7, 'playerStatic': 'Rushing Touchdown', 'param': 'rush_td', 'QOne': 0.0, 'QOneTwo': 0.0, 'QoneThree': 0.0, 'QoneFour': 0.0 , completedColumns: []},
        { id: 8, 'playerStatic': 'Receiving Touchdown', 'param': 'receiving_td', 'QOne': 0.0, 'QOneTwo': 0.0, 'QoneThree': 0.0, 'QoneFour': 0.0 , completedColumns: []},
        { id: 9, 'playerStatic': '35+yard touchdown', 'param': 'yard_td_35_plus', 'QOne': 0.0, 'QOneTwo': 0.0, 'QoneThree': 0.0, 'QoneFour': 0.0 , completedColumns: []},
        { id: 10, 'playerStatic': '55+yard touchdown', 'param': 'yard_td_55_plus', 'QOne': 0.0, 'QOneTwo': 0.0, 'QoneThree': 0.0, 'QoneFour': 0.0 , completedColumns: []},
        { id: 11, 'playerStatic': 'Int thrown', 'param': 'int_thrown', 'QOne': 0.0, 'QOneTwo': 0.0, 'QoneThree': 0.0, 'QoneFour': 0.0 , completedColumns: []},
        { id: 12, 'playerStatic': 'Fumble lost', 'param': 'fumble_lost', 'QOne': 0.0, 'QOneTwo': 0.0, 'QoneThree': 0.0, 'QoneFour': 0.0 , completedColumns: []},
        { id: 13, 'playerStatic': '2pt conv pass', 'param': 'conv_pass_2_pt', 'QOne': 0.0, 'QOneTwo': 0.0, 'QoneThree': 0.0, 'QoneFour': 0.0 , completedColumns: []},
        { id: 14, 'playerStatic': '2pt Conv run', 'param': 'conv_run_2_pt', 'QOne': 0.0, 'QOneTwo': 0.0, 'QoneThree': 0.0, 'QoneFour': 0.0 , completedColumns: []},
        { id: 15, 'playerStatic': '2pt conv reception', 'param': 'conv_rec_2_pt', 'QOne': 0.0, 'QOneTwo': 0.0, 'QoneThree': 0.0, 'QoneFour': 0.0 , completedColumns: []},
        { id: 16, 'playerStatic': '300-399 yard game(pass)', 'param': 'yard_game_pass_300_399', 'QOne': 0.0, 'QOneTwo': 0.0, 'QoneThree': 0.0, 'QoneFour': 0.0 , completedColumns: []},
        { id: 17, 'playerStatic': '400+ yard game(pass)', 'param': 'yard_game_pass_400_plus', 'QOne': 0.0, 'QOneTwo': 0.0, 'QoneThree': 0.0, 'QoneFour': 0.0 , completedColumns: []},
        { id: 18, 'playerStatic': '100-199 yard game(rush/rec)', 'param': 'yard_game_rush_rec_100_199', 'QOne': 0.0, 'QOneTwo': 0.0, 'QoneThree': 0.0, 'QoneFour': 0.0 , completedColumns: []},
        { id: 19, 'playerStatic': '200+ yard game(rush/rec)', 'param': 'yard_game_rush_rec_200_plus', 'QOne': 0.0, 'QOneTwo': 0.0, 'QoneThree': 0.0, 'QoneFour': 0.0 , completedColumns: []},
        { id: 20, 'playerStatic': 'MVP', 'param': 'mvp', 'QOne': 0.0, 'QOneTwo': 0.0, 'QoneThree': 0.0, 'QoneFour': 0.0 , completedColumns: []},
        { id: 21, 'playerStatic': 'OPOY', 'param': 'opoy', 'QOne': 0.0, 'QOneTwo': 0.0, 'QoneThree': 0.0, 'QoneFour': 0.0 , completedColumns: []},
        { id: 22, 'playerStatic': 'DPOY', 'param': 'dpoy', 'QOne': 0.0, 'QOneTwo': 0.0, 'QoneThree': 0.0, 'QoneFour': 0.0 , completedColumns: []},
        { id: 23, 'playerStatic': 'OROY', 'param': 'oroy', 'QOne': 0.0, 'QOneTwo': 0.0, 'QoneThree': 0.0, 'QoneFour': 0.0 , completedColumns: []},
        { id: 24, 'playerStatic': 'DROY', 'param': 'droy', 'QOne': 0.0, 'QOneTwo': 0.0, 'QoneThree': 0.0, 'QoneFour': 0.0 , completedColumns: []},
        { id: 25, 'playerStatic': 'MIP', 'param': 'mip', 'QOne': 0.0, 'QOneTwo': 0.0, 'QoneThree': 0.0, 'QoneFour': 0.0 , completedColumns: []},
        { id: 26, 'playerStatic': 'AP1', 'param': 'ap1', 'QOne': 0.0, 'QOneTwo': 0.0, 'QoneThree': 0.0, 'QoneFour': 0.0 , completedColumns: []},
        { id: 27, 'playerStatic': 'AP2', 'param': 'ap2', 'QOne': 0.0, 'QOneTwo': 0.0, 'QoneThree': 0.0, 'QoneFour': 0.0 , completedColumns: []},
        { id: 28, 'playerStatic': 'ProBowl', 'param': 'pro_bowl', 'QOne': 0.0, 'QOneTwo': 0.0, 'QoneThree': 0.0, 'QoneFour': 0.0 , completedColumns: []},

    ]
}

export const positions = [
    { name: 'CB', value: 'CB' },
    { name: 'DB', value: 'DB' },
    { name: 'DE', value: 'DE' },
    { name: 'DT', value: 'DT' },
    { name: 'EDGE', value: 'EDGE' },
    { name: 'FB', value: 'FB' },
    { name: 'K', value: 'K' },
    { name: 'LB', value: 'LB' },
    { name: 'LS', value: 'LS' },
    { name: 'OLB', value: 'OLB' },
    { name: 'P', value: 'P' },
    { name: 'QB', value: 'QB' },
    { name: 'RB', value: 'RB' },
    { name: 'SAF', value: 'SAF' },
    { name: 'TE', value: 'TE' },
    { name: 'WR', value: 'WR' },
    { name: 'IOL', value: 'IOL' },
    { name: 'DL', value: 'DL' },
    { name: 'S', value: 'S' },
    { name: 'Running Back', value: 'Running Back'},
    { name: 'Tight End', value: 'Tight End'},
    { name: 'Wide Receiver', value: 'Wide Receiver'},
    { name: 'Quarterback', value: 'Quarterback'},
]

export const classes = [
    { name: 'Junior', value: 'Junior' },
    { name: 'Senior', value: 'Senior' },
    { name: 'R-Junior', value: 'R-Junior' },
    { name: 'R-Senior', value: 'R-Senior' },
    { name: 'Sophomore', value: 'Sophomore' },
    { name: 'Freshman', value: 'Freshman' },
]

export const weeks = [{ "name": "Week 1", "value": 1 }, { "name": "Week 2", "value": 2 }, { "name": "Week 3", "value": 3 }, { "name": "Week 4", "value": 4 }, { "name": "Week 5", "value": 5 }, { "name": "Week 6", "value": 6 }, { "name": "Week 7", "value": 7 }, { "name": "Week 8", "value": 8 }, { "name": "Week 9", "value": 9 }, { "name": "Week 10", "value": 10 }, { "name": "Week 11", "value": 11 }, { "name": "Week 12", "value": 12 }, { "name": "Week 13", "value": 13 }, { "name": "Week 14", "value": 14 }, { "name": "Week 15", "value": 15 }, { "name": "Week 16", "value": 16 }, { "name": "Week 17", "value": 17 }, { "name": "Week 18", "value": 18 }];
export const rounds = [{ 'name': 'Round 1', 'value': 1 }, { 'name': 'Round 2', 'value': 2 }, { 'name': 'Round 3', 'value': 3 }, { 'name': 'Round 4', 'value': 4 }, { 'name': 'Round 5', 'value': 5 }, { 'name': 'Round 6', 'value': 6 }, { 'name': 'Round 7', 'value': 7 }];


export const performanceDataBody = (data,desiredColumnsToUpdate) => {
    let perfList = [
        {
            "quarter": "Q1",
        },
        {
            "quarter": "Q1_Q2",
        },
        {
            "quarter": "Q1_Q3",
        },
        {
            "quarter": "Q1_Q4",
        }
    ]
    perfList.forEach(perf => {
        performance_default_values().forEach((defaultPerf, defaultPerfIndex) => {
            if (perf.quarter === "Q1" && desiredColumnsToUpdate.includes(perf.quarter)) {
                perf[defaultPerf.param] = data[defaultPerfIndex].QOne
            }
            if (perf.quarter === "Q1_Q2" && desiredColumnsToUpdate.includes(perf.quarter)) {
                perf[defaultPerf.param] = data[defaultPerfIndex].QOneTwo
            }
            if (perf.quarter === "Q1_Q3" && desiredColumnsToUpdate.includes(perf.quarter)) {
                perf[defaultPerf.param] = data[defaultPerfIndex].QoneThree
            }
            if (perf.quarter === "Q1_Q4" && desiredColumnsToUpdate.includes(perf.quarter)) {
                perf[defaultPerf.param] = data[defaultPerfIndex].QoneFour
            }
        })
    });
    const filteredPerfList = perfList.filter(performance => Object.keys(performance).length > 1); // this is to filter the other columns which are not entered and consider only the entered columns
    return filteredPerfList
}