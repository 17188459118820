import React from 'react';
import { useLocation } from 'react-router-dom';
import './headerSeparator.css';
import Heading from './headingComponent';
import { moduleHeader } from '../../Utils/routes';

const HeaderSeparator = () =>{
    const { pathname } = useLocation();
    
    return (
        <>
            <Heading title={moduleHeader[pathname]} showSeparator={true} />
        </>
    );
}

export default HeaderSeparator;