import { FormControl, MenuItem, Select } from "@mui/material";
import React from "react";
import CustomInputLabel from "../InputLabel/customInputLabel";
import { isUndefined } from "../../Utils/utils";

const CustomSelect = (props) => {
    const { options, label, onChange, selectedVal, error ,disabled} = props;

    const onSelectChange = (e) =>{
        onChange(e.target.value)
    }

    return (
        <>
            <CustomInputLabel label={label}/>
            <FormControl fullWidth>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedVal}
                    error={error}
                    onChange={onSelectChange}
                    disabled={disabled}
                    sx={{fontWeight : 600 , backgroundColor : !isUndefined(disabled) && disabled && '#e1e1e1'}}>
                    {
                        options.map((option) => <MenuItem value={option.value}>{option.name}</MenuItem>)
                    }
                </Select>
            </FormControl>
        </>
    )
}

export default CustomSelect