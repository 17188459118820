import React from 'react';
import './headerSeparator.css';
import Separator from '../Seperator/separator';

const Heading =({ title }) =>{
    return (
        <div className='page-header'>
            <label className='page-header-title'>{title}</label>
        </div>
    );
}

export default Heading;