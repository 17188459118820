import React from "react";
import { Box, Typography, Stack, Grid, Dialog, DialogContent, DialogContentText, DialogActions, DialogTitle, IconButton } from "@mui/material";

import CancelIcon from '@mui/icons-material/Cancel';
import { isUndefined } from "../../Utils/utils";
import CustomButton from "../../Shared/Button/button";
import { BUTTON_COLOR_VARIANTS } from "../../Utils/constants";

const CustomModal = ({ showModal, message, onClick, isCancel, onCancel, updateModalState }) => {

    const onButtonClick = () => {
        if (!isUndefined(onClick)) {
            onClick()
        }
    }

    const onCancelClick = () => {
        if (!isUndefined(onCancel)) onCancel()
        else updateModalState()
    }

    function BootstrapDialogTitle(props) {
        const { children, onClose, ...other } = props;

        return (
            <DialogTitle sx={{ m: 0, p: 3 }} {...other}>
                {onClose && (
                    <IconButton aria-label="close" onClick={onClose} sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500], }}>
                        <CancelIcon />
                    </IconButton>
                )}
            </DialogTitle>
        );
    }

    return (
        <Dialog open={showModal} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" fullWidth maxWidth="lg" >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={onCancelClick} />
            <DialogContent >
                <DialogContentText id="alert-dialog-description">
                    <Box sx={{ textAlign: "center" }}>
                        <Stack marginBottom={5} >
                            <Typography variant="h5" fontWeight={500}>{message}</Typography>
                        </Stack>
                    </Box>
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ paddingBottom: 3 }}>
                <Grid container alignItems="center" justifyContent="center" spacing={3}>
                    {
                        !isUndefined(isCancel) && isCancel &&
                        <Grid item>
                            <CustomButton title="CANCEL" variant={"outlined"} onClick={onCancelClick} />
                        </Grid>
                    }
                    <Grid item>
                        <CustomButton title="OK" variant="contained" colorVariant={BUTTON_COLOR_VARIANTS.BLUE} onClick={onButtonClick} />
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

export default CustomModal;
