import { convertToNumber, isEmailValidated, isEmpty, isNameValidated, isNameValidatedIncludingSpaces, isNull, isNumberGreaterThanOne, isUndefined } from "./utils";

export function validateFirstName(name){
    if(!isUndefined(name) && !isNull(name) && !isEmpty(name)){
        if(!isNameValidated(name)){
            return "Enter valid first name"
        }
    }else{
        return "Enter first name"
    }
    return ""
}

export function validateLastName(name,nameType){
    if(!isUndefined(name) && !isNull(name) && !isEmpty(name)){
        if(!isNameValidated(name)){
            return "Enter valid last name"
        }
    }else{
        return "Enter last name"
    }
    return ""
}

export function validateEmail(email){
    if(!isUndefined(email) && !isNull(email) && !isEmpty(email)){
        if(!isEmailValidated(email)){
            return "Enter valid email"
        }
    }else{
        return "Enter email address"
    }
    return ""
}

export function validatePassword(password){
    if(!isUndefined(password) && !isNull(password) && isEmpty(password)){
            return "Enter Password"
    }
    return ""
}

export function validateConfirmPassword(confirmPassword){
    if(!isUndefined(confirmPassword) && !isNull(confirmPassword) && isEmpty(confirmPassword)){
        return "Enter Confirm Password"
    }
    return ""
}

export function matchPasswords(password , confirmPassword){
    if(password !== confirmPassword){
        return "Passwords do not match"
    }
    return ""
}

export function validatePick(pickNum){
    if(!isUndefined(pickNum) && !isNull(pickNum) && !isEmpty(pickNum)){
        if(parseInt(pickNum) < 1){
            return "Pick number must be greater than one"
        }
    }
    return ""
}

export function validateOtp(otp){
    if(!isUndefined(otp) && !isNull(otp) && isEmpty(otp)){
        return "Enter OTP"
    }else if(otp.length !== 6){
        return "Enter a valid OTP"
    }
    return ""
}



