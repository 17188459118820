import React, { useEffect, useState } from "react";
import { Box, Typography, Stack, Grid, Dialog, DialogContent, DialogContentText, DialogActions, DialogTitle, IconButton } from "@mui/material";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { AppColors } from "../../Utils/colors";
import { isUndefined } from "../../Utils/utils";
import CustomButton from "../../Shared/Button/button";

const ResponseModal = (props) => {
  const { showModal, message, statusCode, onClick } = props;
  const [isModalOpen , setModalOpen ] = useState(false)

  const getModalStatusIcon = () => {
    if (!isUndefined(statusCode)) {
      if (statusCode === 200 || statusCode === 201) return <CheckCircleIcon sx={{ fontSize: '70px', color: AppColors.success.main }} />
      else return <ReportProblemIcon sx={{ fontSize: '70px', color: AppColors.warning.main }} />
    }
    return <></>
  }

  useEffect(()=>{
    setModalOpen(showModal) // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const onButtonClick = () =>{
    if(!isUndefined(onClick)) {
      onClick()
    }
  }

  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle sx={{ m: 0, p: 3 }} {...other}>
        {/* {children} */}
        {onClose && (
          <IconButton aria-label="close" onClick={onClose} sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500], }}>
            <CancelIcon fontSize='large' />
          </IconButton>
        )}
      </DialogTitle>
    );
  }

  return (
    <Dialog open={isModalOpen} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" fullWidth maxWidth="sm">
      <BootstrapDialogTitle id="customized-dialog-title" onClose={onButtonClick} />
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Box sx={{ textAlign: "center" }}>
            {getModalStatusIcon()}
            <Stack marginTop={5} marginBottom={3}>
              <Typography variant="h5" fontWeight={500}>{message}</Typography>
            </Stack>
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item marginBottom={2}>
            <CustomButton name="OK" color="primary" type="submit" onClick={onButtonClick} />
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ResponseModal;
