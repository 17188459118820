export function isEmpty(str) {
    return str === ''
}

export function isNull(str) {
    return str === null
}

export function isUndefined(str) {
    return str === undefined
}

export function isArrayEmpty(list) {
    return list.length === 0
}

export function isNonZero(value) {
    return value !== 0
}

export function isStringValidated(str){
    return !isUndefined(str) && !isNull(str) && !isEmpty(str)
}

export function isNumeric(str) {
    var numericRegex = /^[-+]?[0-9]*\.?[0-9]+$/;
    return numericRegex.test(str);
}

export function convertToNumber(str) {
    if (/^[-+]?[0-9]*\.?[0-9]+$/.test(str)) {
        if (str.indexOf(".") !== -1) {
            return parseFloat(str);
        } else {
            return parseInt(str, 10);
        }
    }
    return NaN;
}

export function getInitialsForName(fullName) {
    let initials='';
    if(!isUndefined(fullName)&&!isEmpty(fullName)){
        let nameArr = fullName.trim().split(" ");
        if (nameArr.length > 1){
            const firstChars = nameArr.map((str,index) => {if(index <= 1) {return str.charAt(0)}});
            initials = firstChars.join('');
        }else if (nameArr.length === 1){
            initials = nameArr[0].charAt(0)
        }
    }
    return initials.toUpperCase()
}

export function getRandomColorCode() {
    var red = Math.floor(Math.random() * 128);
    var green = Math.floor(Math.random() * 128);
    var blue = Math.floor(Math.random() * 128);
    var darkColor = "#" + red.toString(16) + green.toString(16) + blue.toString(16);
    return darkColor;
}

export function isNameValidated(name){
    const nameRegex = /^[A-Za-z\s\-',.]+$/;
    return nameRegex.test(name);
}

export function isNameValidatedIncludingSpaces(name){
    const nameRegex = /^[a-zA-Z\s]+$/;
    return nameRegex.test(name);
}

export function isEmailValidated(email){
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}
export function isObjectEmpty(object){
    return Object.keys(object).length === 0
}
export function isObjecthasEmptyValues(object){
    return Object.values(object).every(o => o === "");
}
export function isObjecthasNullValues(object){
    return Object.values(object).every(o => o === null);
}

export function isNumberGreaterThanOne(str) {
    var pattern = /^[1-9]\d*\.?\d+?$/;
    return pattern.test(str);
}

export function csvJSON(csv, headers, limit) {
    var lines = csv.split("\n");
    var result = [];
    for (var i = 0; i <= lines.length-1; i++) {
        var obj = {};
        var currentline = lines[i].split(",");
        for (var j = 0; j < headers.length; j++) {
            let line = 0
            if (!isUndefined(currentline[j]) && !isNull(currentline[j]) && !isEmpty(currentline[j])) {
                line = currentline[j].replace(/(\r\n|\n|\r)/gm, "")
                line = parseInt(line)
            }
            obj[headers[j]] = line;
        }
        result.push(obj);
    }
    return JSON.stringify(result);
}

export const mergeArray = (arrayA,arrayB) => {
    const mergedArray = arrayA.map((itemA, index) => {
        const itemB = !isUndefined(arrayB[index]) ? arrayB[index] : 0;
        return { ...itemA, ...itemB }
      });
    return mergedArray
}

export function generateSeasonYears(startYear, endYear) {
    const seasonYears = [];
    for (let year = startYear; year <= endYear; year++) {
        // seasonYears.push({ name: `${year}-${(year + 1).toString().substring(2)}`, value: `${year}-${year + 1}`})
        seasonYears.push(
            {
                name: `${year}-${(year + 1).toString().substring(2)}`,
                value: `${year}-${(year + 1).toString().substring(2)}`
            }
        )
    }
    return seasonYears;
}

export function removeTrailingDecimalZeros(value) {
    // Check if the value is a number
    if (typeof value !== 'number') {
      return value; // Return as is if it's not a number
    }
    
    // Check if the number has no decimal part
    if (value % 1 === 0) {
      return Math.floor(value); // Remove the decimal part
    } else {
      return value; // Keep the decimal part
    }
  }
  


