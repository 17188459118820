import React from "react";
import { isUndefined } from "../../Utils/utils";
import { Button } from "@mui/material";
import "./button.css"
import { APP_BUTTON_COLORS } from "../../Utils/constants";

const CustomButton = (props) => {
    const {id, title, icon, color, variant , colorVariant , onClick , type , value , isDisabled} = props;
    
    function setButtonBgColor() {
        if(!isUndefined(colorVariant)){
            return APP_BUTTON_COLORS[colorVariant]
        }else{
            return APP_BUTTON_COLORS.WHITE
        }
    }
    return (
        <Button
            id={!isUndefined(id) ? id : "CUSTOM_BUTTON"}
            className="custom-btn"
            variant={variant}
            onClick={onClick}
            style={{'backgroundColor': setButtonBgColor() , 'minWidth' : '150px'}}
            type={type}
            startIcon={!isUndefined(icon) && icon}
            value={value}
            disabled={!isUndefined(isDisabled) && isDisabled}>
            {title}
            
        </Button>
    )
}

export default CustomButton;