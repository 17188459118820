import React from "react";
import "./Header.css";
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import commonservice from "../../Services/Commonservice"
import { navigateToLogin } from "../../Utils/navigation";
import MenuIcon from '@mui/icons-material/Menu';
// import NotificationsIcon from '@mui/icons-material/Notifications';
import { connect, useDispatch, useSelector } from "react-redux";
import { collapseSidebar, expandSidebar } from "../../store/reduxActions";
import { isNull } from "../../Utils/utils";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
// import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
// import MailIcon from '@mui/icons-material/Mail';
import {clearSession} from "../../Utils/localStorage";
const Header = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isSidebarShown = useSelector(state => state.isSidebarShown)

  var profiledetail = commonservice.getuserprofile()
  var profilename = profiledetail[0];
  var role = profiledetail[1];
  const menuId = 'primary-search-account-menu';

  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // calls when sidemenu component is changed

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={() => logout()}>Logout</MenuItem>
    </Menu>
  );

  function logout() {
    setAnchorEl(null);
    clearSession();
    navigateToLogin(navigate);
   
  }

  function hideSidebar() {
    dispatch(isSidebarShown ? collapseSidebar : expandSidebar)
  }

  function displayNiceProfileName(name) {
    return !isNull(name) ? name.split(" ")[0] : ""
  }

  function isExplicit() {
    return pathname !== "/" && pathname !== "/Login"
  }


  return (
    isExplicit() && (
      <section>
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static" className="nav">
            <Toolbar>
              <IconButton size="large" edge="start" color="inherit" aria-label="open drawer" sx={{ mr: 2 }} onClick={() => hideSidebar()}><MenuIcon /></IconButton>
              <Box sx={{ flexGrow: 1 }}><img src="/asset/app-logo-transparent.png" alt="sample" className={"logo"} /></Box>
              <Box sx={{ display: {  md: 'flex' } }}>
                {/* <IconButton size="large" aria-label="show 4 new mails" color="inherit"><Badge badgeContent={1} color="error"><MailIcon /></Badge></IconButton>
                <IconButton size="large" aria-label="show new notifications" color="inherit"><Badge badgeContent={1} color="error"><NotificationsIcon /></Badge></IconButton> */}
              </Box>
              <Box sx={{ display: {  md: 'flex' } }}>
                <div className="profile-details-wrapper">
                  <div className={"profilename"}>{displayNiceProfileName(profilename)} </div>
                  <div className={"roledescription"}>{role}</div>
                </div>
              </Box>
              <Box sx={{ display: {  md: 'flex' } }}>
                <IconButton size="large" edge="end" aria-label="account of current user" aria-controls={menuId} aria-haspopup="true" onClick={handleProfileMenuOpen} color="inherit"><AccountCircle /></IconButton>
              </Box>
            </Toolbar>
          </AppBar>
          {renderMenu}
        </Box>
      </section>
    )
  )
}

export default connect(store => store)(Header);