import React from "react";
import TextField from '@mui/material/TextField';
import './searchBar.css';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { isUndefined } from "../../Utils/utils";

const SearchBar = ({ show, searchKey, setSearchKey , onKeyDown , placeholder}) => {
    return (
        <TextField
            hiddenLabel
            id="filled-hidden-label-small"
            variant="filled"
            placeholder={!isUndefined(placeholder) ? placeholder : "Search"}
            className="search-field-input"
            InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                disableUnderline: true
              }}
            value={searchKey}
            onChange={setSearchKey}
            onKeyDown={onKeyDown}
        />
        
    )
}

export default SearchBar;