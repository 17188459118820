import { legacy_createStore as createStore } from "redux"
import { REDUX_ACTIONS } from "../Utils/constants"

const initialState = {
    isSidebarShown : true,
    axiosResponse : null
}

function handleState(state = initialState, action) {
    switch (action.type) {
        case REDUX_ACTIONS.TOGGLE_SIDEBAR:
            return {
                ...state,
                isSidebarShown: action.isSidebarShown
            }
        case REDUX_ACTIONS.RESPONSE_HANDLER:
            return {
                ...state,
                axiosResponse: action.axiosResponse
            }
        default:
            return { ...state }
    }
}

const store = createStore(handleState)

export default store;