import React, { useState } from "react";
import './modal.css'
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { isObjectEmpty, isObjecthasEmptyValues, isUndefined } from "../../Utils/utils";
import { Grid, IconButton, Stack } from "@mui/material";
import CustomTextField from "../TextField/customTextField";
import CustomButton from "../Button/button";
import { BUTTON_COLOR_VARIANTS } from "../../Utils/constants";
import axios from "axios";
import { URL } from "../../Utils/url";
import { validateConfirmPassword, validateEmail, validateOtp, validatePassword } from "../../Utils/validation";
import Close from '@mui/icons-material/Close';
import ArrowBack from '@mui/icons-material/ArrowBack';
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { generateOtpForPasswordReset, resetPassword } from "../../Utils/services";

const ForgotPasswordModal = ({ isShow, onClose, title, description }) => {
    const [formData, setFormData] = useState({ email: '', otp: '', password: '', confirmPassword: '' });
    const [formErrors, setFormErrors] = useState({});
    const [isOtpGenerated, setOtp] = useState(false);

    const toggleForgotPasswordScreen = () => setOtp(!isOtpGenerated)

    const onTextChange = (previousState, param, newValue) => {
        if (param === 'email') setFormErrors({ ...formErrors, [param]: validateEmail(newValue) })
        if (param === 'otp') setFormErrors({ ...formErrors, [param]: validateOtp(newValue) })
        if (param === 'password') setFormErrors({ ...formErrors, [param]: validatePassword(newValue) })
        if (param === 'confirmPassword') setFormErrors({ ...formErrors, [param]: validateConfirmPassword(newValue) })
        setFormData({ ...previousState, [param]: newValue })
    }

    const generateOtp = async () => {
        if (isFormReadyToSendOtp(formData)) {
            let form = { admin_email: formData.email }
            await generateOtpForPasswordReset(form).then((res)=>{
                if(res.statusCode === 200) {
                    toggleForgotPasswordScreen()
                }
            })
        }
    }

    const resetPasswordService = async () => {
        if (isFormReadyToResetPassword(formData)) {
            let form = { new_password: formData.password, confirm_password: formData.confirmPassword }
            let params = { admin_email: formData.email, otp: formData.otp }
            await resetPassword(form,params).then((res)=>{
                if(res.statusCode === 200){
                    toggleForgotPasswordScreen()
                    closeModal()
                }
            })
        }
    }

    function closeModal(){
        setFormData({})
        setFormErrors({})
        onClose()
    }

    function isFormReadyToSendOtp(data) {
        let errors = {};
        errors.email = validateEmail(!isUndefined(data) ? data.email : '')
        setFormErrors(errors)
        return !isObjectEmpty(errors) && isObjecthasEmptyValues(errors);
    }

    function isFormReadyToResetPassword(data) {
        let errors = {};
        errors.password = validatePassword(!isUndefined(data) ? data.password : '');
        errors.otp = validateOtp(!isUndefined(data) ? data.otp : '');
        errors.confirmPassword = validateConfirmPassword(!isUndefined(data) ? data.confirmPassword : '');
        setFormErrors(errors)
        return !isObjectEmpty(errors) && isObjecthasEmptyValues(errors);
    }

    return (
        <Modal open={isShow} onClose={()=>closeModal()} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" >
            <div className="modal-box">
                <Grid container >
                    <Grid item md={6} className="modal-header-grid-wrapper-left">
                        {isOtpGenerated && <IconButton size="small" aria-label="open drawer" onClick={toggleForgotPasswordScreen}><ArrowBack /></IconButton>}
                    </Grid>
                    <Grid item md={6} className="modal-header-grid-wrapper-right">
                        <IconButton size="small" aria-label="open drawer" onClick={() => closeModal()}><Close /></IconButton>
                    </Grid>
                </Grid>
                
                
                <div className="modal-box-wrapper">
                    <Typography variant="h5" textAlign={'center'}>{title}</Typography>
                    {!isUndefined(description) && <Typography textAlign={'center'}>{description}</Typography>}
                    <Stack spacing={3}>
                        {
                            !isOtpGenerated ?
                                <>
                                    <CustomTextField
                                        placeholder="Enter your email address"
                                        value={formData.email}
                                        onChange={(val) => onTextChange(formData, 'email', val)}
                                        error={Boolean(formErrors.email)}
                                        helperText={formErrors.email} />
                                    <CustomButton
                                        variant="contained"
                                        onClick={generateOtp}
                                        title={"Generate OTP"}
                                        colorVariant={BUTTON_COLOR_VARIANTS.BLUE}
                                    />
                                </> :
                                <>
                                    <CustomTextField
                                        value={formData.otp}
                                        placeholder="Enter OTP"
                                        onChange={(val) => onTextChange(formData, 'otp', val)}
                                        textFieldVariant={"nonDecimal"}
                                        type={"number"}
                                        maxLength={6} 
                                        error={Boolean(formErrors.otp)}
                                        helperText={formErrors.otp}/>
                                    <CustomTextField
                                        placeholder="Password"
                                        value={formData.password}
                                        type={'password'}
                                        onChange={(val) => onTextChange(formData, 'password', val)}
                                        error={Boolean(formErrors.password)}
                                        helperText={formErrors.password} />
                                    <CustomTextField
                                        placeholder="Confirm Password"
                                        value={formData.confirmPassword}
                                        type={'password'}
                                        onChange={(val) => onTextChange(formData, 'confirmPassword', val)}
                                        error={Boolean(formErrors.confirmPassword)}
                                        helperText={formErrors.confirmPassword} />
                                    <CustomButton
                                        variant="contained"
                                        onClick={resetPasswordService}
                                        title={"Submit"}
                                        colorVariant={BUTTON_COLOR_VARIANTS.BLUE}
                                    />
                                </>
                        }
                    </Stack>
                </div>
            </div>
        </Modal>
    )
}

export default ForgotPasswordModal