export const AUTH_DETAILS_KEY = '__user_info';
export const LOGGED_IN_USER_KEY = 'LoggedInUserName';


export function saveLoginDetails(data){
    localStorage.setItem("LoggedInUserName", data.adminName)
    localStorage.setItem(AUTH_DETAILS_KEY, JSON.stringify(data))
    localStorage.setItem('token',data.jwt)
}

export function getAuthDetails(){
    return JSON.parse(localStorage.getItem(AUTH_DETAILS_KEY))
}

export function clearSession(){
    localStorage.removeItem(AUTH_DETAILS_KEY);
    localStorage.removeItem('token'); // Clear the token
  localStorage.removeItem("LoggedInUserName"); // Clear the user name as well
}