import { Autocomplete, Box, FormControl, InputLabel, TextField } from "@mui/material";
import React from "react";
import './autoCompleteField.css'
import CustomInputLabel from "../InputLabel/customInputLabel";

const AutoCompleteField = (props) => {

    const { label, value, onChange, options, error } = props;

    return (
        <>
            <CustomInputLabel label={label}/>
            <FormControl fullWidth>
                <Autocomplete
                    disablePortal
                    autoComplete
                    name={label}
                    options={options.map((option) => option.name)}
                    onChange={onChange}
                    value={value}
                    renderInput={(params) => (
                        <React.Fragment>
                            <TextField {...params} variant="outlined" error={error}  placeholder={label} />
                        </React.Fragment>
                    )}
                />
            </FormControl>
        </>
    )
}

export default AutoCompleteField