import { REDUX_ACTIONS } from "../Utils/constants"

export const expandSidebar = {
    type: REDUX_ACTIONS.TOGGLE_SIDEBAR,
    isSidebarShown: true
}

export const collapseSidebar = {
    type: REDUX_ACTIONS.TOGGLE_SIDEBAR,
    isSidebarShown: false
}

export const setAxiosResponse = (response) => {
    return {
        type : REDUX_ACTIONS.RESPONSE_HANDLER,
        axiosResponse : response
    }
}