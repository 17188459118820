import React from "react";
import "./Dashboard.css";


const Dashboard = () =>{
    
    return (

        <>Dashboard Coming Soon</>
    )
}

export default Dashboard;