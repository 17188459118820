import * as React from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { Avatar, Typography } from '@mui/material';
import './viewPlayers.css';
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { viewPlayer, viewPlayerPerformance } from '../../../../Utils/services';
import { getInitialsForName, getRandomColorCode, isUndefined } from '../../../../Utils/utils';
import Loader from '../../../../Shared/Progress/loader';
import { performance_default_values } from '../CreatePlayers/data/createPlayerCreationData';

const ViewPlayers = () =>{
    const navigate = useNavigate();
    let { path_player_id } = useParams();
    const [viewEmployeeInfo, setEmployeeInfo] = useState('');
    const [empPerfInfo, setPerfInfo] = useState('');
    const [loadSpinner, setLoadSpinner] = useState(false);
    const [uploadFile, setUpload] = useState('');
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    

    useEffect(() => {
        getPlayerInfo();
        forceUpdate();
    }, [])

    async function getPlayerInfo() {
        setLoadSpinner(true)
        let param = {player_id: path_player_id}
        await viewPlayer(param).then((res) => {
            let tempres = res.body;
            setEmployeeInfo(tempres.PlayerDetails);
            setUpload(tempres.PlayerDetails.player_photo);
            setPerfInfo(tempres.PlayerPerformance[0])
            setLoadSpinner(false)
        }).catch(() => setLoadSpinner(false))
    }

    async function getPlayerPerformanceInfo() {
        let param = {player_id: path_player_id}
        await viewPlayerPerformance(param).then((res) => {
            let tempres = res['data'];
            setPerfInfo(tempres[0])
            setLoadSpinner(false)
        }).catch(() => setLoadSpinner(false))
    }

    const onFilechange = (e) => {
        setUpload(URL.createObjectURL(e.target.files[0]));
    }

    function playerPage(value) {
        localStorage.setItem('viewData', JSON.stringify(value));
        navigate('/Players');
    }

    function editPlayer() {
        navigate('/Players/player/' + path_player_id);
    }

    return (
        <div className={"createplayerContainer"}>
            <Loader show={loadSpinner}/>
            <div className="playersDiv">
                <Grid container className="topCDivDesign">
                    <Grid item md={3}>
                        <Grid container item>
                            <Card className='threePage'>
                                <CardContent className='cardContent'>
                                    <div className='backGreayV'>
                                        <div className='profilePicV'>
                                            <Avatar sx={{ bgcolor: getRandomColorCode(), width: 120, height: 120, fontSize: 50 }}>{getInitialsForName(viewEmployeeInfo.player_name)}</Avatar>
                                        </div>
                                    </div>
                                    <div className='backDGreayV'/>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    <Grid item md={9} container>
                        <Grid item md={7} className='paddingLt marginTopT'>
                            <Typography className='playerName textLeft' gutterBottom variant="h5" component="div">
                                {viewEmployeeInfo.player_name}
                            </Typography>
                            <Typography className='RondsName textLeft' gutterBottom variant="h5" component="div">
                                {viewEmployeeInfo.status}
                            </Typography>
                            <Grid className='marginBottom' container>
                                <Grid item md={4}>
                                    <Typography className='textLeft paraGraph' gutterBottom variant="p" component="div">
                                        Position
                                    </Typography>
                                    <Typography className='textLeft headFive' gutterBottom variant="h5" component="div">
                                        {viewEmployeeInfo.position}
                                    </Typography>
                                </Grid>
                                <Grid item md={8}>
                                    <Typography className='textLeft paraGraph' gutterBottom variant="p" component="div">
                                        College
                                    </Typography>
                                    <Typography className='textLeft headFive' gutterBottom variant="h5" component="div">
                                        {viewEmployeeInfo.college}
                                    </Typography>
                                </Grid>
                            </Grid>

                        </Grid>
                        <Grid item md={5} className="backCrdName">
                            <div className='imageDivi'>
                                {/* <img className='imageLogo' alt='Tag' src='/asset/app-logo-transparent.png'></img> */}
                            </div>
                            <Typography className='RondsName' gutterBottom padding={2} variant="h5" component="div">
                                {!isUndefined(viewEmployeeInfo.team_name) && viewEmployeeInfo.team_name}
                            </Typography>
                        </Grid>
                        <Grid container className='backgroundLB paddingLt paddingRT padTB'>
                            <Grid item md={3}>
                                <Typography className='textLeft paraGraph' gutterBottom variant="p" component="div">
                                    Class
                                </Typography>
                                <Typography className='textLeft headFive' gutterBottom variant="h5" component="div">
                                    {viewEmployeeInfo.player_class}
                                </Typography>
                            </Grid>
                            <Grid item md={3}>
                                <Typography className='textLeft paraGraph' gutterBottom variant="p" component="div">
                                    Total No. of games
                                </Typography>
                                <Typography className='textLeft headFive' gutterBottom variant="h5" component="div">
                                    {viewEmployeeInfo.number_of_games}
                                </Typography>
                            </Grid>
                            <Grid item md={3}>
                                <Typography className='textLeft paraGraph' gutterBottom variant="p" component="div">
                                    Draft Price
                                </Typography>
                                <Typography className='textLeft headFive' gutterBottom variant="h5" component="div">

                                    ${viewEmployeeInfo.draft_price}
                                </Typography>
                            </Grid>
                            <Grid item md={3}>
                                <Typography className='textRight paraGraph' gutterBottom variant="p" component="div">
                                    Stock Price
                                </Typography>
                                <Typography className='textRight headFive' gutterBottom variant="h5" component="div">
                                    ${viewEmployeeInfo.new_stock_value}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container className='backgroundLB paddingLt paddingRT padBottom'>
                            <Grid item md={3}>
                                <Typography className='textLeft paraGraph' gutterBottom variant="p" component="div">
                                    Week
                                </Typography>
                                <Typography className='textLeft headFive' gutterBottom variant="h5" component="div">
                                    {viewEmployeeInfo.week}
                                </Typography>
                            </Grid>
                            <Grid item md={3}>
                                <Typography className='textLeft paraGraph' gutterBottom variant="p" component="div">
                                    Grade
                                </Typography>
                                <Typography className='textLeft headFive' gutterBottom variant="h5" component="div">
                                    {viewEmployeeInfo.grade}
                                </Typography>
                            </Grid>
                            <Grid item md={3}>
                                <Typography className='textLeft paraGraph' gutterBottom variant="p" component="div">
                                    Round
                                </Typography>
                                <Typography className='textLeft headFive' gutterBottom variant="h5" component="div">
                                    {viewEmployeeInfo.round}
                                </Typography>
                            </Grid>
                            <Grid item md={3}>
                                <Typography className='textRight paraGraph' gutterBottom variant="p" component="div">
                                    Pick Number(Ovr)
                                </Typography>
                                <Typography className='textRight headFive' gutterBottom variant="h5" component="div">
                                    {viewEmployeeInfo.overall_pick}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container className='backgroundLB paddingLt paddingRT padBottom'>
                            <Grid item md={3}>
                                <Typography className='textLeft paraGraph' gutterBottom variant="p" component="div">
                                    Allocated Stocks
                                </Typography>
                                <Typography className='textLeft headFive' gutterBottom variant="h5" component="div">
                                    {viewEmployeeInfo.allocated_stocks}
                                </Typography>
                            </Grid>
                            <Grid item md={3}>
                                <Typography className='textLeft paraGraph' gutterBottom variant="p" component="div">
                                    Stocks Sold out
                                </Typography>
                                <Typography className='textLeft headFive' gutterBottom variant="h5" component="div">
                                    {viewEmployeeInfo.stocks_sold}
                                </Typography>
                            </Grid>
                            <Grid item md={3}>
                                <Typography className='textLeft paraGraph' gutterBottom variant="p" component="div">
                                    Remaining in Stocklete bucket
                                </Typography>
                                <Typography className='textLeft headFive' gutterBottom variant="h5" component="div">
                                    {viewEmployeeInfo.available_stocks_in_stocklete_bucket}
                                </Typography>
                            </Grid>
                            <Grid item md={3}>
                                <Typography className='textRight paraGraph' gutterBottom variant="p" component="div">
                                    League
                                </Typography>
                                <Typography className='textRight headFive' gutterBottom variant="h5" component="div">
                                    {viewEmployeeInfo.sports_name}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container className='backgroundLB'>
                        <Typography className='textLeft playerPerfor' gutterBottom variant="p" component="div">
                            Player Performance
                        </Typography>
                    </Grid>
                    <Grid item container className='backgroundLB paddingLt paddingRT padTop'>
                        <Grid item container >
                            {/* <Grid item > */}
                            {
                            performance_default_values().map((perf) => {
                                return (
                                    <Grid item xs={2} style={{textAlign: "center"}}>
                                        <Typography className='textLeft paraGraph' gutterBottom variant="p" component="div">{perf.playerStatic}</Typography>
                                        <Typography className='textLeft fontWeightB' gutterBottom variant="p" component="div">{empPerfInfo[perf.param]}</Typography>
                                    </Grid>
                                )
                            })
                        }
                                
                            {/* </Grid> */}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item className='textCenter marginTopT'>
                    <Button variant="outlined" className='cancelCss' onClick={() => playerPage()}>Cancel</Button>
                    <Button variant="contained" className='editPlayer' onClick={() => editPlayer()}>Edit</Button>
                </Grid>
            </div>
        </div>
    )
}

export default ViewPlayers