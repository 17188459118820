import React from "react";
import './customTextField'
import { FormControl, TextField } from "@mui/material";
import CustomInputLabel from "../InputLabel/customInputLabel";
import { isUndefined } from "../../Utils/utils";

const CustomTextField = (props) => {
    const { label, value, onChange, error, helperText, required, disabled, type , textFieldVariant , placeholder , maxLength} = props;

    const onTextChange=(e)=>{
        onChange(e.target.value)
    }

    const onTextKeyDown=(e)=>{
        if(textFieldVariant==='nonDecimal'){
            if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+" || e.key === "=" || e.key === ".") {
                e.preventDefault()
            }
        }else if(textFieldVariant==='decimal'){
            if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+" || e.key === "=") {
                e.preventDefault()
            }
        }
    }

    return (
        <>
            <CustomInputLabel label={label} />
            <FormControl fullWidth>
                <TextField
                    className="custom-text-field-input"
                    placeholder={label || placeholder}
                    value={value}
                    onChange={onTextChange}
                    error={error}
                    helperText={helperText}
                    required={required}
                    inputProps={{ style: { fontWeight: 600 , backgroundColor : !isUndefined(disabled) && disabled && '#e1e1e1'} , maxLength : !isUndefined(maxLength) ? maxLength : 50 }}
                    disabled={disabled}
                    type={type}
                    onKeyDown={onTextKeyDown}
                    autoComplete={type === 'password' ? 'new-password' : 'off'}
                />
            </FormControl>
        </>

    )
}

export default CustomTextField