
const commonservice= {
    getuserprofile: function() {
      let name=localStorage.getItem("LoggedInUserName")
        // call api to get the profile details
        let profilename=name;
        let role="Admin"
        return [profilename, role]
      }

}


export default commonservice;

