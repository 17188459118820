import React from "react";
import "./Sidebar.css";
import { NavLink, useLocation } from 'react-router-dom';
import { SidebarData } from "../../Services/Sidemenudataservice";
import { isArrayEmpty } from "../../Utils/utils";
import HeaderSeparator from "../../Components/Header/headerSeparator";
import { connect, useSelector } from "react-redux";


const Sidebar = ({ children }) => {
  const {pathname} = useLocation();
  const isSidebarShown = useSelector(state => state.isSidebarShown)

  function isExplicit(){
    return pathname !== "/" && pathname !== "/Login"
  }

  return (
    <>
      <div className={isExplicit() ? "myContainer" : ""}>
        {
          isExplicit() &&
          <div className="sidebar" style={isSidebarShown ? {'width':'17em'} : {'width':'5em','textAlign':'center'}}>
          {
            !isArrayEmpty(SidebarData) && SidebarData.map((item, index) => (
              <NavLink to={item.path} key={index} className="link" >
                  <div className="menu_section">
                    <div className="icon" title={!isSidebarShown && item.title }><item.icon /></div>
                    {
                      isSidebarShown && <div className="link_text">{item.title}</div>
                    }
                  </div>
                </NavLink>
            ))
          }
        </div>
        }
        {
          isExplicit() ?
          <main className='main-body'>
            <div className="main-content-wrapper">
              <HeaderSeparator />
              <div className='main-content'>{children}</div>
            </div>
          </main>
          :<div>{children}</div>
        }
      </div>
    </>
  )
}

export default connect(store=>store)(Sidebar);