import axios from 'axios';
import { URL } from './url';
import { isUndefined } from './utils';
export const BASE_API_URL = process.env.REACT_APP_API_KEY; // DEV


function returnErrorStructure(response) {
    var responseStructure = { statusCode: null, body: null, statusMessage: null }
    if (!isUndefined(response)) {
        responseStructure.statusCode = response.status;
        responseStructure.body = null;
        responseStructure.statusMessage = !isUndefined(response.data.message) && response.data.message;
    }
    return responseStructure
}

function returnSuccessResponse(response) {
    var responseStructure = { statusCode: null, body: null, statusMessage: null }
    if (!isUndefined(response)) {
        responseStructure.statusCode = response.status;
        responseStructure.body = response.data.data;
        responseStructure.statusMessage = response.data.message;
    }
    return responseStructure
}

export const login = async (form) => {
    return await axios.post(URL.LOGIN_URL, form)
        .then((response) => returnSuccessResponse(response))
        .catch((error) => returnErrorStructure(error))
}

// export const getPlayersList = async (params) => {
//     return await axios.get(URL.PLAYERS_LIST, { params: params })
//         .then((response) => returnSuccessResponse(response))
//         .catch((error) => returnErrorStructure(error))
// }
export const getPlayersList = async (params) => {
    const token = localStorage.getItem('token');
    if (!token) {
        console.error('Token not found in localStorage');
        return returnErrorStructure(new Error('Token not found'));
    }
    const headers = { 'Authorization': `Bearer ${token}` };
    try{
        const response = await axios.get(URL.PLAYERS_LIST, { headers: headers, params: params });
        console.log('API Response:', response.data);
        return returnSuccessResponse(response);
    }
    catch (error) {
        return returnErrorStructure(error);
    }
};
   

export const addPlayerPricing = async (body) => {
    return await axios.post(URL.ADD_PLAYER_PRICING, body)
        .then((response) => returnSuccessResponse(response))
        .catch((error) => returnErrorStructure(error))
}

// export const updateDraftPrice = async (params) => {
//     return await axios.patch(URL.UPDATE_DRAFT_PRICE, params, { params: params })
//         .then((response) => returnSuccessResponse(response))
//         .catch((error) => returnErrorStructure(error))
// }
export const updateDraftPrice = async (params) => {
    const token = localStorage.getItem('token'); // Retrieve the token from localStorage
    const headers = { 'Authorization': `Bearer ${token}` }; // Create headers with the token
    try{
        const response = await axios.patch(URL.UPDATE_DRAFT_PRICE, params, { headers: headers, params: params });
        return returnSuccessResponse(response);
    } catch(error){
        return returnErrorStructure(error);
    }
};

// export const addPlayer = async (body) => {
//     return await axios.post(URL.CREATE_PLAYER, body)
//         .then((response) => returnSuccessResponse(response))
//         .catch((error) => returnErrorStructure(error))
// }

export const addPlayer = async (body) => {
    const token = localStorage.getItem('token'); // Retrieve the token from localStorage
    const headers = { 'Authorization': `Bearer ${token}` }; // Create headers with the token
    try {
        const response = await axios.post(URL.CREATE_PLAYER, body, { headers }); // Send the request with the headers
        return returnSuccessResponse(response);
    } catch (error) {
        return returnErrorStructure(error);
    }
};

// export const getCollegeList = async () => {
//     return await axios.get(URL.COLLEGE_LIST)
//         .then((response) => returnSuccessResponse(response))
//         .catch((error) => returnErrorStructure(error))
// }
export const getCollegeList = async () => {
    const token = localStorage.getItem('token'); // Retrieve the token from localStorage
    const headers = {'Authorization': `Bearer ${token}`};
    try{
        const response = await axios.get(URL.COLLEGE_LIST, {headers: headers});
        return returnSuccessResponse(response);
    } catch (error){
        return returnErrorStructure(error);
    }
};

// export const getTeamsList = async () => {
//     return await axios.get(URL.TEAMS_LIST)
//         .then((response) => returnSuccessResponse(response))
//         .catch((error) => returnErrorStructure(error))
// }
export const getTeamsList = async () => {
    const token = localStorage.getItem('token'); // Retrieve the token from localStorage
    const headers = {'Authorization': `Bearer ${token}`};
    try{
        const response = await axios.get(URL.TEAMS_LIST, {headers: headers});
        return returnSuccessResponse(response);
    } catch (error){
        return returnErrorStructure(error);
    }
};

export const getLeagueList = async () => {
    const token = localStorage.getItem('token'); // Retrieve the token from localStorage
    const headers = {'Authorization': `Bearer ${token}`};
    try{
        const response = await axios.get(URL.LEAGUE_LIST, {headers: headers});
        return returnSuccessResponse(response);
    } catch (error){
        return returnErrorStructure(error);
    }
};

// export const viewPlayer = async (params) => {
//     return await axios.get(URL.VIEW_PLAYER, { params: params })
//         .then((response) => returnSuccessResponse(response))
//         .catch((error) => returnErrorStructure(error))
// }
export const viewPlayer = async (params) => {
    const token = localStorage.getItem('token');
    if (!token) {
        console.error('Token not found in localStorage');
        return returnErrorStructure(new Error('Token not found'));
    }
    const headers = { 'Authorization': `Bearer ${token}` };

    try {
        const response = await axios.get(URL.VIEW_PLAYER, {
            params: params,
            headers: headers
        });
        console.log('API Response:', response.data);
        return returnSuccessResponse(response);
    } catch (error) {
        return returnErrorStructure(error);
    }
};

export const viewPlayerPerformance = async (params) => {
    return await axios.get(URL.VIEW_PLAYER_PERFORMANCE, { params: params })
        .then((response) => returnSuccessResponse(response))
        .catch((error) => returnErrorStructure(error))
}

// export const updatePlayer = async (body, params) => {
//     return await axios.patch(URL.UPDATE_PLAYER, body)
//         .then((response) => returnSuccessResponse(response))
//         .catch((error) => returnErrorStructure(error))
// }
export const updatePlayer = async (body, params) => {
    const token = localStorage.getItem('token'); // Retrieve the token from localStorage
    const headers = { 'Authorization': `Bearer ${token}` }; // Create headers with the token
    try{
        const response = await axios.patch(URL.UPDATE_PLAYER, body, { headers: headers, params:params});
        return returnSuccessResponse(response);
    }
    catch(error){
        return returnErrorStructure(error);
    }
};

export const updatePlayerPerformance = async (body, params) => {
    return await axios.patch(URL.UPDATE_PLAYER_PERFORMANCE, body, { params: params })
        .then((response) => returnSuccessResponse(response))
        .catch((error) => returnErrorStructure(error))
}
// export const updatePlayerPerformance = async (body, params) => {
//     const token = localStorage.getItem('token'); // Retrieve the token from localStorage
//     const headers = { 
//         'Authorization': `Bearer ${token}`,
//         'Content-Type': 'multipart/form-data' // Specify the content type for FormData
//     };
    
//     try {
//         const response = await axios.patch(URL.UPDATE_PLAYER_PERFORMANCE, body, { headers: headers,params:params})
//         return returnSuccessResponse(response)
//     } catch (error){
//         returnErrorStructure(error)
//     }
// }

// export const deletePlayer = async (body) => {
//     return await axios.patch(URL.DELETE_PLAYER, body)
//         .then((response) => returnSuccessResponse(response))
//         .catch((error) => returnErrorStructure(error))
// }
export const deletePlayer = async (body) => {
    const token = localStorage.getItem('token'); // Retrieve the token from localStorage
    const headers = { 
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data' // Specify the content type for FormData
    };
    
    try {
        const response = await axios.patch(URL.DELETE_PLAYER, body, { headers: headers });
        return returnSuccessResponse(response);
    } catch (error) {
        return returnErrorStructure(error);
    }
};

export const updatePlayerPerformancePrice = async (params) => {
    return await axios.patch(URL.UPDATE_PLAYER_PERFORMANCE_PRICE, params, { params: params })
        .then((response) => returnSuccessResponse(response))
        .catch((error) => returnErrorStructure(error))
}
// export const updatePlayerPerformancePrice = async (params) => {
//     const token = localStorage.getItem('token'); // Retrieve the token from localStorage
//     const headers = { 
//         'Authorization': `Bearer ${token}`,
//         'Content-Type': 'multipart/form-data' // Specify the content type for FormData
//     };
    
//     try {
//         const response = await axios.patch(URL.UPDATE_PLAYER_PERFORMANCE_PRICE, {headers: headers, params:params});
//         return returnSuccessResponse(response);
//     }catch (error){
//         return returnErrorStructure(error);
//     }
// };

export const generateOtpForPasswordReset = async (body) => {
    return await axios.post(URL.FORGOT_PASSWORD, body)
    .then((response) => returnSuccessResponse(response))
    .catch((error) => returnErrorStructure(error))
}

export const resetPassword = async (body, params) => {
    return await axios.patch(URL.RESET_PASSWORD, body, { params: params })
        .then((response) => returnSuccessResponse(response))
        .catch((error) => returnErrorStructure(error))
}
