import './App.css';
import React from 'react';
import Sidebar from './Shared/SideBar/Sidebar'
import Header from './Shared/Header/Header';
import { useState } from 'react';
import Dashboard from './Components/Administrator/Dashboard/Dashboard'
import Login from './Components/Authentication/Login/Login'
import {  Routes, Route } from 'react-router-dom';
import Players from './Components/Administrator/Players/Players';
import CreatePlayers from './Components/Administrator/Players/CreatePlayers/CreatePlayersNew';
import ViewPlayers from './Components/Administrator/Players/viewPlayers/viewPlayers';
import { initiateInterceptor } from './Services/AxiosInterceptor';
import { connect, useDispatch, useSelector } from 'react-redux';
import ResponseModal from './Components/Modals/responseModal';
import { isNull } from './Utils/utils';
import { setAxiosResponse } from './store/reduxActions';


const App = () => {
  const [sidebarOpen, setSideBarOpen] = useState(false);
  const dispatch = useDispatch();
  const axiosResponse = useSelector(state => state.axiosResponse)
  const handleViewSidebar = () => setSideBarOpen(!sidebarOpen)
  const handleErrorModal = () => dispatch(setAxiosResponse(null))

  initiateInterceptor(dispatch).request();
  initiateInterceptor(dispatch).response();

  return (
    <>
    {!isNull(axiosResponse) && <ResponseModal showModal={axiosResponse.showModal} onClose={handleErrorModal} title={axiosResponse.title} message={axiosResponse.message} statusCode={axiosResponse.statusCode} onClick={handleErrorModal}/>}
      <Header isOpen={sidebarOpen} />
      <Sidebar onClick={handleViewSidebar} isOpen={sidebarOpen}>
        <Routes>
          <Route exact path='/' element={< Login />}></Route>
          <Route exact path='/Login' element={< Login />}></Route>
          <Route exact path='/Dashboard' element={< Dashboard />}></Route>
          <Route exact path='/Players' element={<Players/>}></Route>
          <Route exact path='/Players/player/:path_player_id?' element={< CreatePlayers />}></Route>
          <Route exact path='/Players/viewPlayer/:path_player_id?' element={< ViewPlayers />}>
          </Route>
        </Routes>
      </Sidebar>
    </>
  );
}

export default connect(store=>store)(App);
