import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { Avatar, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import './CreatePlayers.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Input from '@mui/material/Input';
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { addPlayer, addPlayerPricing, getCollegeList, getTeamsList, updateDraftPrice, updatePlayerPerformance, updatePlayer, viewPlayer, viewPlayerPerformance, updatePlayerPerformancePrice,getLeagueList } from '../../../../Utils/services';
import { BUTTON_COLOR_VARIANTS, PLAYER_QUARTER_1, PLAYER_QUARTER_1_2, PLAYER_QUARTER_1_3, PLAYER_QUARTER_1_4 } from '../../../../Utils/constants';
import { convertToNumber, csvJSON, generateSeasonYears, getInitialsForName, isArrayEmpty, isEmpty, isNull, isObjectEmpty, isObjecthasEmptyValues, isStringValidated, isUndefined, mergeArray, removeTrailingDecimalZeros } from '../../../../Utils/utils';
import CustomTextField from '../../../../Shared/TextField/customTextField';
import CustomSelect from '../../../../Shared/Select/customSelect';
import { classes, performanceDataBody, performance_default_values, positions, rounds, weeks } from './data/createPlayerCreationData';
import AutoCompleteField from '../../../../Shared/Autocomplete/autoCompleteField';
import CustomButton from '../../../../Shared/Button/button';
import { validateFirstName, validateLastName, validatePick } from '../../../../Utils/validation';
import Loader from '../../../../Shared/Progress/loader';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import AddIcon from '@mui/icons-material/Add';


const CreatePlayers = () =>{
    const navigate = useNavigate();
    const [submitBtnVal,setSubmitBtnVal] = useState(0);
    let { path_player_id } = useParams();
    const [teams, setTeams] = useState([]);
    const [colleges, setColleges] = useState([]);
    const [loadSpinner, setLoadSpinner] = useState(false);
    const [performanceData, setPerformanceData] = useState([]);
    const [tempPerfData, setTempPerfData] = useState([]);
    const [playerDetails, setPlayerDetails] = useState({ allocated_stocks: 7500, year: '2023-24', week: 1 });
    const [leagues, setLeagues] = useState([]);
    const [formErrors, setFormErrors] = useState({});
    const [profilePicture, setProfilePicture] = useState();
    const [playerPositions , setPlayerPositions] = useState([]);
    const commonParam = { player_id: path_player_id }
    const [updatedPlayerPerformancePriceDetails , setUpdatedPlayerPerformancePriceDetails] = useState({stock_price : 0 , previous_price : 0})
    const [recentlyUpdatedColumns, setRecentlyUpdatedColumns] = useState([]);
    const [isWeekCompleted , setIsWeekCompleted] = useState(false);


    //upload image 
    useEffect(() => {
        getTeamvalues();
        getCollegevalues();
        getLeaguevalues();
        initializeValues({updatePlayer : true});
    }, [])

    async function getCollegevalues() {
        await getCollegeList().then((res) => {
            setColleges(getCollegeListForAutocomplete(res.body))
        }).catch(() => setLoadSpinner(false))
    }

    async function getTeamvalues() {
        await getTeamsList().then((res) => {
            setTeams(getTeamsListForAutocomplete(res.body))
        }).catch(() => setLoadSpinner(false))
    }

    async function getLeaguevalues() {
        await getLeagueList().then((res) => {
          console.log('Leagues fetched:', res.body);  // Debug log
          setLeagues(getLeagueListForAutocomplete(res.body))
        }).catch(() => setLoadSpinner(false))
      }

    async function initializeValues({updatePlayer = false , week = 1, year = ''}) {
        if (!isUndefined(path_player_id)) {
            setLoadSpinner(true)
            let params = { player_id: path_player_id, week: week }
            await viewPlayer(params).then((res) => {
                let response = res.body;
                if(updatePlayer) setPlayerDetails(response.PlayerDetails)
                setUpdatedPlayerPerformancePriceDetails({stock_price : response.PlayerDetails.new_stock_value , previous_price : response.PlayerDetails.current_stock_value})
                setPerformanceData(wrapPlayersPerformanceData(response.PlayerPerformance))
                setTempPerfData(wrapPlayersPerformanceData(response.PlayerPerformance))
                setIsWeekCompleted(isWeekIsCompleted(wrapPlayersPerformanceData(response.PlayerPerformance)))
                setLoadSpinner(false)
            }).catch(() => setLoadSpinner(false))
        }
        else{
            setPerformanceData(performance_default_values)
            setTempPerfData(performance_default_values)
            setIsWeekCompleted(isWeekIsCompleted(performance_default_values))
        }
        setPlayerPositions(positions.sort(sortPositions))
    }

    function getTeamsListForAutocomplete(list) {
        let newList = [];
        list.forEach(element => {
            let field = { name: element.name, value: element.name, icon: element.logo }
            newList.push(field)
        });
        return newList
    }

    function getCollegeListForAutocomplete(list) {
        let newList = [];
        list.forEach(element => {
            let field = { name: element.college_name, value: element.college_name }
            newList.push(field)
        });
        return newList
    }
    function getLeagueListForAutocomplete(list) {
        let newList = [];
        list.forEach(element => {
          let field = { name: element.sports_name, value: element.sports_name, id:element.id}
          newList.push(field)
        });
        console.log('Formatted Leagues:', newList);  // Debug log
        return newList
      }
    function wrapPlayersPerformanceData(data) {
        let performanceTableData = performance_default_values()
        performanceTableData.forEach((performance_default_value) => {
            let colCount = ''
            data.forEach((entry) => { // Assigning the param values based on each quarter
                if (entry.quarter === PLAYER_QUARTER_1) {
                    performance_default_value.QOne = entry[performance_default_value.param]
                    if(entry.is_completed) colCount = colCount + "," + 1
                }
                else if (entry.quarter === PLAYER_QUARTER_1_2) {
                    performance_default_value.QOneTwo = entry[performance_default_value.param]
                    if(entry.is_completed) colCount = colCount + ","  + 2
                }
                else if (entry.quarter === PLAYER_QUARTER_1_3) {
                    performance_default_value.QoneThree = entry[performance_default_value.param]
                    if(entry.is_completed) colCount = colCount + ","  + 3
                }
                else if (entry.quarter === PLAYER_QUARTER_1_4) {
                    performance_default_value.QoneFour = entry[performance_default_value.param]
                    if(entry.is_completed) colCount = colCount + ","  + 4
                }
                performance_default_value.completedColumns = colCount.split(",").filter(i => !isEmpty(i)) //getting only the columns which are marked as completed
            })
        })
        return performanceTableData
    }

    const onTextChange = (previousState, param, newValue) => {
        if (param === "first_name") setFormErrors({ ...formErrors, first_name: validateFirstName(newValue) })
        if (param === "last_name") setFormErrors({ ...formErrors, last_name: validateLastName(newValue) })
        if (param === "pick") setFormErrors({ ...formErrors, pick: validatePick(newValue) })

        setPlayerDetails({ ...previousState, [param]: newValue }); // Setting the state as per the field parameters

        if (param === "pick" && !isUndefined(path_player_id) && playerDetails.round && isStringValidated(newValue)) {
            if (isEmpty(validatePick(newValue))) calculateDraftPrice(playerDetails.round, newValue) // Calling the API only if pick number validation is done
        } else if (param === "round" && !isUndefined(path_player_id) && playerDetails.pick && isStringValidated(newValue)) {
            if (isPickNumberValidated(playerDetails)) calculateDraftPrice(newValue, playerDetails.pick) // Calling the API only if pick number validation is done
        }
    }

    const onSelection = (previousState, param, newValue) =>{
        setPlayerDetails({ ...previousState, [param]: newValue })
        if(param === "year"){
            initializeValues({week : playerDetails.week , year : newValue})
        }else if (param === "week"){
            initializeValues({week : newValue , year : playerDetails.year})
        }
    }

    async function calculateDraftPrice(roundvalue, picknum) {
        let params = { player_id: path_player_id, round_num: roundvalue, pick: picknum }
        await updateDraftPrice(params).then((response) => {
            if (!isNull(response.body.draft_price)) {
                setTimeout(() => {
                    setPlayerDetails({ ...playerDetails, draft_price: response.body.draft_price, round: roundvalue, pick: picknum });
                }, 300)
            }
        }).catch(() => setLoadSpinner(false))
    }

    const onFilechange = (e) => {
        setProfilePicture(URL.createObjectURL(e.target.files[0]));
    }

    const handleTableDataChange = (event, performanceId, key , fieldParamName) => {
        let data = performanceData;
        let value = removeTrailingDecimalZeros(event.target.value)
        const updatedTableData = data.map((row) => {
            if (row.id === performanceId) {
                return { ...row, [key]: convertToNumber(value) };
            }
            return row;
        });
        if(!recentlyUpdatedColumns.includes(fieldParamName)) setRecentlyUpdatedColumns(cols => [...cols , fieldParamName])
        setPerformanceData(updatedTableData);
        setIsWeekCompleted(isWeekIsCompleted(updatedTableData))
    };

    const isFormValidated = (data) => {
        let errors = {};
        errors.first_name = validateFirstName(!isUndefined(data) ? data.first_name : '')
        errors.last_name = validateLastName(!isUndefined(data) ? data.last_name : '');
        setFormErrors(errors)
        return !isObjectEmpty(errors) && isObjecthasEmptyValues(errors);
    };

    const isPickNumberValidated = (data) => {
        let errors = {};
        errors.pick = validatePick(!isUndefined(data) ? data.pick : 0)
        setFormErrors(errors)
        return !isObjectEmpty(errors) && isObjecthasEmptyValues(errors);
    }

    const getFullName = () => {
        if (!isUndefined(playerDetails)) {
            var firstName, lastName;
            firstName = !isUndefined(playerDetails.first_name) ? playerDetails.first_name : ''
            lastName = !isUndefined(playerDetails.last_name) ? playerDetails.last_name : ''
            return firstName + " " + lastName
        }
        return ''
    }

    const submitPlayer = (e) => {
        e.preventDefault();
        if (isFormValidated(playerDetails)) {
            if(!isUndefined(path_player_id)) updatePlayerDetails()
            else addPlayerDetails()
        }
        window.scrollTo(0, 0)
    }

    async function addPlayerDetails(){
        setLoadSpinner(true)
        let body = {
            player: {
                "first_name": playerDetails.first_name,
                "last_name": playerDetails.last_name,
                "position": playerDetails.position,
                "college": playerDetails.college,
                "player_class": playerDetails.player_class,
                "team_name": playerDetails.team_name,
                "sports_name":playerDetails.sports_name,
                "total_games": parseInt(playerDetails.total_games),
                "grade": parseFloat(playerDetails.grade),
                "week": parseInt(playerDetails.week),
                "round": parseInt(playerDetails.round),
                "pick": parseInt(playerDetails.pick),
                "overall_pick": parseInt(playerDetails.overall_pick),
                "current_stock_value": 0,
                "new_stock_value": 0,
                "allocated_stocks" : parseInt(playerDetails.allocated_stocks),
            },
            performance : performanceDataBody(performanceData,[])
        }
        await addPlayer(body).then((res) => {
            addPlayerPricingDetails(res.body.player_id);
        }).catch(() => setLoadSpinner(false)) 
    }

    async function addPlayerPricingDetails(player_id){
        let body = {"player": player_id}
        await addPlayerPricing(body).then((res)=>{
            setLoadSpinner(false)
            if(submitBtnVal === "1")navigate('/Players')
            else if(submitBtnVal === "2")window.location.reload()
        }).catch((error) => setLoadSpinner(false))
    }

    async function updatePlayerDetails(){
        setLoadSpinner(true)
        let body = {
            "first_name": playerDetails.first_name,
            "last_name": playerDetails.last_name,
            "team_name": playerDetails.team_name,
            "sports_name":playerDetails.sports_name,
            "position": playerDetails.position,
            "college": playerDetails.college,
            "player_class": playerDetails.player_class,
            "total_games": parseInt(playerDetails.number_of_games),
            "grade": parseFloat(playerDetails.grade),
            "week": parseInt(playerDetails.week),
            "player_id": playerDetails.player_id,
            "allocated_stocks" : parseInt(playerDetails.allocated_stocks)
        }
        await updatePlayer(body,commonParam).then((res)=>{
            setLoadSpinner(false)
            navigate('/Players')
        }).catch(()=>setLoadSpinner(false))
    }

    async function updatePlayerPerformanceService(){ // Updating the performance table values
        setLoadSpinner(true)
        let body = {performance : performanceDataBody(performanceData,recentlyUpdatedColumns)};
        let params = {'player_id' : path_player_id , 'week' : playerDetails.week}
        await updatePlayerPerformance(body,params).then(()=>{
             updatePlayerPerformancePriceService()
        })
    }

    async function updatePlayerPerformancePriceService() { // Generating the stock price once after updating the performance table values
        let params = { 'player_id': parseInt(path_player_id) , 'quarter' : recentlyUpdatedColumns.join(',') }
        await updatePlayerPerformancePrice(params).then((res) => {
            setLoadSpinner(false)
            if(res.statusCode === 200){
                setUpdatedPlayerPerformancePriceDetails({stock_price : res.body.stock_price.stock_price , previous_price : res.body.previous_price})
                performanceData.forEach((data)=>{
                    recentlyUpdatedColumns.forEach(recData=>{
                        if(recData === PLAYER_QUARTER_1) data.completedColumns.push('1')
                        if(recData === PLAYER_QUARTER_1_2) data.completedColumns.push('2')
                        if(recData === PLAYER_QUARTER_1_3) data.completedColumns.push('3')
                        if(recData === PLAYER_QUARTER_1_4) data.completedColumns.push('4')
                    })
                })
                setIsWeekCompleted(isWeekIsCompleted(performanceData))
                setTempPerfData(performanceData)
            }else{
                setPerformanceData(tempPerfData)
            }
            setRecentlyUpdatedColumns([])
        }).catch(()=>{
            setLoadSpinner(false)
        })
    }

    function sortPositions(a, b) {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        if (nameA < nameB) return -1
        if (nameA > nameB) return 1
        return 0;
    }

    function importData(csvFile){
        let headers = ['QOne','QOneTwo','QoneThree','QoneFour']
        let data = csvJSON(csvFile,headers) // csv file , headers has to be passed as arguments to convert csv to json
        
        setPerformanceData(mergeArray(performanceData , JSON.parse(data)))
        setTempPerfData(mergeArray(performanceData , JSON.parse(data)))
    }
    
    const onImportData = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
            const contents = e.target.result;
            importData(contents)
        };
        reader.readAsText(file);
    }

    function isWeekIsCompleted(data){
        let is_completed = false;
        if(!isUndefined(path_player_id)){
            data.forEach((perf)=>{
                is_completed = perf.completedColumns.length === 4
            })   
        } else {is_completed = true}
        
        return is_completed
    }
    
      

    return (
        <>
            <div className={"createplayerContainer"}>
            <Loader show={loadSpinner}/>
                <form onSubmit={submitPlayer}>
                    <Grid container className="topCDivDesign" spacing={2}>
                        <Grid item md={9} className="paddingR">
                            <Grid container item spacing={4} className="topDivSpace">
                                <Grid item md={4}>
                                    <CustomTextField
                                        label="First Name"
                                        value={!isUndefined(playerDetails) ? playerDetails.first_name : ''}
                                        onChange={(val) => onTextChange(playerDetails, 'first_name', val)}
                                        error={Boolean(formErrors.first_name)}
                                        helperText={formErrors.first_name}
                                    />
                                </Grid>
                                <Grid item md={4}>
                                    <CustomTextField
                                        label="Last Name"
                                        value={!isUndefined(playerDetails) ? playerDetails.last_name : ''}
                                        onChange={(val) => onTextChange(playerDetails, 'last_name', val)}
                                        error={Boolean(formErrors.last_name)}
                                        helperText={formErrors.last_name}
                                    />
                                </Grid>
                                <Grid item md={4}>
                                    <CustomSelect
                                        label={"Position"}
                                        options={playerPositions}
                                        selectedVal={!isUndefined(playerDetails.position) ? playerDetails.position : 'Select a position'}
                                        onChange={(val) => onTextChange(playerDetails, 'position', val)}
                                        error={Boolean(formErrors.position)}
                                        helperText={formErrors.position}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item spacing={4} className="topDivSpace">
                                <Grid item md={4}>
                                    <AutoCompleteField
                                        label={"College"}
                                        value={!isUndefined(playerDetails.college) ? playerDetails.college : ''}
                                        onChange={(e, value) => onTextChange(playerDetails, 'college', value)}
                                        options={colleges}
                                        error={Boolean(formErrors.college)}
                                        helperText={formErrors.college}
                                    />
                                </Grid>
                                <Grid item md={4}>
                                    <CustomSelect
                                        label={"Class"}
                                        options={classes}
                                        selectedVal={!isUndefined(playerDetails.player_class) ? playerDetails.player_class : ''}
                                        onChange={(val) => onTextChange(playerDetails, 'player_class', val)}
                                        error={Boolean(formErrors.player_class)}
                                        helperText={formErrors.player_class}
                                    />
                                </Grid>
                                <Grid item md={4}>
                                    <AutoCompleteField
                                        label={"Team"}
                                        value={!isUndefined(playerDetails.team_name) ? playerDetails.team_name : ''}
                                        onChange={(e, value) => onTextChange(playerDetails, 'team_name', value)}
                                        options={teams}
                                        error={Boolean(formErrors.team_name)}
                                        helperText={formErrors.team_name}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item spacing={4} className="topDivSpace">
                                <Grid item md={4}>
                                    <CustomTextField
                                        label="Grade"
                                        value={!isUndefined(playerDetails.grade) ? playerDetails.grade : ''}
                                        onChange={(val) => onTextChange(playerDetails, 'grade', val)}
                                        error={Boolean(formErrors.grade)}
                                        helperText={formErrors.grade}
                                        type={"number"}
                                        textFieldVariant={"decimal"} />
                                </Grid>
                                <Grid item md={4}>
                                    <CustomTextField
                                        label="Total Games"
                                        value={!isUndefined(playerDetails.number_of_games) ? playerDetails.number_of_games : ''}
                                        onChange={(val) => onTextChange(playerDetails, 'number_of_games', val)}
                                        error={Boolean(formErrors.number_of_games)}
                                        helperText={formErrors.number_of_games}
                                        type={"number"}
                                        textFieldVariant={"nonDecimal"} />
                                </Grid>
                                <Grid item md={4}>
                                    <Grid container spacing={2}>
                                        <Grid item md={6}>
                                            <CustomSelect
                                                label={"Year"}
                                                options={generateSeasonYears(2023,2030)}
                                                selectedVal={!isUndefined(playerDetails) ? playerDetails.year : ''}
                                                onChange={(val) => onSelection(playerDetails, 'year', val)}
                                                error={Boolean(formErrors.year)}
                                                helperText={formErrors.year}
                                                disabled = {isUndefined(path_player_id)}
                                            />
                                        </Grid>
                                        <Grid item md={6}>
                                            <CustomSelect
                                                label={"Week"}
                                                options={weeks}
                                                selectedVal={!isUndefined(playerDetails) ? playerDetails.week : ''}
                                                onChange={(val) => onSelection(playerDetails, 'week', val)}
                                                error={Boolean(formErrors.week)}
                                                helperText={formErrors.week}
                                                disabled = {isUndefined(path_player_id)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container item spacing={4} className="topDivSpace">
                                <Grid item md={4}>
                                    <CustomSelect
                                        label={"Round"}
                                        options={rounds}
                                        selectedVal={!isUndefined(playerDetails.round) && !isNull(playerDetails.round) ? playerDetails.round.toString() : ''}
                                        onChange={(val) => onTextChange(playerDetails, 'round', val)}
                                        error={Boolean(formErrors.round)}
                                        helperText={formErrors.round}
                                        disabled={isUndefined(path_player_id)}
                                    />
                                </Grid>
                                <Grid item md={4}>
                                    <CustomTextField
                                        label="Pick Number"
                                        value={!isUndefined(playerDetails) ? playerDetails.pick : ''}
                                        onChange={(val) => onTextChange(playerDetails, 'pick', val)}
                                        error={Boolean(formErrors.pick)}
                                        helperText={formErrors.pick}
                                        disabled={isUndefined(path_player_id)}
                                        textFieldVariant={"nonDecimal"} 
                                        type={"number"}/>
                                </Grid>
                                <Grid item md={4}>
                                    <CustomTextField
                                        label="Pick Ovr."
                                        value={!isUndefined(playerDetails) ? playerDetails.overall_pick : ''}
                                        onChange={(val) => onTextChange(playerDetails, 'overall_pick', val)}
                                        error={Boolean(formErrors.overall_pick)}
                                        helperText={formErrors.overall_pick}
                                        disabled={true}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item spacing={4} className="topDivSpace">
                                <Grid item >
                                    <CustomTextField
                                        label="Allocated Stocks"
                                        value={!isUndefined(playerDetails) ? playerDetails.allocated_stocks : ''}
                                        onChange={(val) => onTextChange(playerDetails, 'allocated_stocks', val)}
                                        error={Boolean(formErrors.allocated_stocks)}
                                        helperText={formErrors.allocated_stocks}
                                        textFieldVariant={"nonDecimal"} 
                                        type={"number"}
                                    />
                                </Grid>
                            <Grid item md={4}>
                                <AutoCompleteField
                                    label={"League"}
                                    options={leagues}
                                    value={!isUndefined(playerDetails.sports_name) ? playerDetails.sports_name : ''}
                                    onChange={(e, value) => onTextChange(playerDetails, 'sports_name', value)}
                                    error={Boolean(formErrors.sports_name)}
                                    helperText={formErrors.sports_name}
                                />
                            </Grid>
                            </Grid>
                            <Grid container item spacing={5} className="topDivSpace">
                                <Grid item md={7} className="textLeft">
                                    <Typography variant='h4' className='playerPerH' >Player Performance</Typography>
                                </Grid>
                                <Grid item md={2} className="textRight">
                                    <CustomButton variant="contained" title={"Import"} icon={<AddIcon />} colorVariant={BUTTON_COLOR_VARIANTS.GREEN} />
                                    {/* <Button variant="contained" >
                                        <Input type='file' onChange={(e) => onImportData(e)} className='fileInput' />
                                        Import
                                    </Button> */}
                                </Grid>
                                <Grid item md={3} className="textRight">
                                    <CustomButton variant="contained" title={"Generate Price"} icon={<RestartAltIcon />} colorVariant={BUTTON_COLOR_VARIANTS.BLUE} isDisabled={isWeekCompleted} onClick={()=>updatePlayerPerformanceService()} />
                                </Grid>
                                <Grid item md={12}>
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead className="tableHead">
                                                <TableRow>
                                                    <TableCell align="center" className='colorWhite playerStatic'>Player Static</TableCell>
                                                    <TableCell align="center" className='colorWhite'>Q1</TableCell>
                                                    <TableCell align="center" className='colorWhite'>Q1:Q2</TableCell>
                                                    <TableCell align="center" className='colorWhite'>Q1:Q3</TableCell>
                                                    <TableCell align="center" className='colorWhite'>Q1:Q4</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                   performanceData.map((performance, performanceIndex) => {
                                                        return (
                                                            <TableRow key={performanceIndex} >
                                                                <TableCell align="center">{performance.playerStatic}</TableCell>
                                                                <TableCell align="center">
                                                                    <Input type='number' onWheel={(e) => e.target.blur()} min="0" minv inputMode="decimal" id={performance.QOne} defaultValue={0} value={removeTrailingDecimalZeros(performance.QOne)} className='performance-table-input' onChange={(event) => handleTableDataChange(event, performance.id, 'QOne' , 'Q1')} disabled={performance.completedColumns.includes("1")}/>
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    <Input type='number' onWheel={(e) => e.target.blur()} min="0" minv inputMode="decimal" id={performance.QOneTwo} defaultValue={0} value={removeTrailingDecimalZeros(performance.QOneTwo)} className='performance-table-input'  onChange={(event) => handleTableDataChange(event, performance.id, 'QOneTwo' , 'Q1_Q2')} disabled={performance.completedColumns.includes("2")}/>
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    <Input type='number' onWheel={(e) => e.target.blur()} min="0" minv inputMode="decimal" id={performance.QoneThree} defaultValue={0} value={removeTrailingDecimalZeros(performance.QoneThree)} className='performance-table-input'  onChange={(event) => handleTableDataChange(event, performance.id, 'QoneThree' , 'Q1_Q3')} disabled={performance.completedColumns.includes("3")}/>
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    <Input type='number' onWheel={(e) => e.target.blur()} min="0" minv inputMode="decimal" id={performance.QoneFour} defaultValue={0} value={removeTrailingDecimalZeros(performance.QoneFour)} className='performance-table-input'  onChange={(event) => handleTableDataChange(event, performance.id, 'QoneFour' , 'Q1_Q4')} disabled={performance.completedColumns.includes("4")}/>
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    })
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>

                            </Grid>
                        </Grid>
                        <Grid item md={3}>
                            <Grid container item className='topDivSpace'>
                                <Card className='threePage profileWrapper'>
                                    <CardContent className='cardContent'>
                                        <div className='backDGreay'>
                                            <Typography gutterBottom variant="p" component="div">
                                                Player Photo
                                            </Typography>
                                            <div className='profilePic'>
                                                {/* {!isUndefined(playerDetails) && !isUndefined(playerDetails.player_photo) && <img className='imageResponse' alt='Profile' src={playerDetails.player_photo} />} */}
                                                <Avatar sx={{ bgcolor: '#013369', width: 120, height: 120, fontSize: 50 }}>{getInitialsForName(getFullName())}</Avatar>
                                            </div>
                                        </div>
                                        <div className='backGreay'>
                                            <Button variant="contained" className='UpPlayerphoto'>
                                                <Input type='file' onChange={(e) => onFilechange(e)} className='fileInput' />
                                                Upload Player Photo
                                            </Button>
                                        </div>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid container item className='topDivSpace'>
                                <Typography className='stockEvualation' gutterBottom variant="h5" component="div">
                                    Stock valuation
                                </Typography>
                                <Card className='threePage currentStock'>
                                    <CardContent className='cardContent'>
                                        <Typography className='colorSecon stockPara' gutterBottom variant="p" component="div">
                                            Draft Price
                                        </Typography>
                                        <Typography className='stockEvualationP colorSecon' gutterBottom variant="h5" component="div">
                                            $ {!isUndefined(playerDetails) ? playerDetails.draft_price : ''}
                                        </Typography>
                                    </CardContent>
                                </Card>
                                <Grid container direction={"row"} spacing={2}>
                                    <Grid item md={6} xs={6}>
                                        <Card className='newStockV'>
                                            <CardContent className='cardContent'>
                                                <Typography className='colorSecon stockPara' gutterBottom variant="p" component="div">
                                                    Stock Price
                                                </Typography>
                                                <Typography className='stockPriceDetails colorSecon' gutterBottom variant="p" component="div">
                                                    $ {!isUndefined(updatedPlayerPerformancePriceDetails) ? updatedPlayerPerformancePriceDetails.stock_price : ''}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item md={6} xs={6}>
                                        <Card className='oldStockV'>
                                            <CardContent className='cardContent'>
                                                <Typography className='colorSecon stockPara' gutterBottom variant="p" component="div">
                                                    Previous Price
                                                </Typography>
                                                <Typography className='stockPriceDetails colorSecon' gutterBottom variant="p" component="div">
                                                    $ {!isUndefined(updatedPlayerPerformancePriceDetails) ? updatedPlayerPerformancePriceDetails.previous_price : ''}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                                <Grid container item className='submit-container'>
                                    <CustomButton value={1} onClick={(e)=>setSubmitBtnVal(e.target.value)} id={'submit'} type={"submit"} variant="contained" title={"Submit"} colorVariant={BUTTON_COLOR_VARIANTS.BLUE} />
                                </Grid>
                                {
                                    isUndefined(path_player_id) &&
                                    <Grid container item className='submit-container'>
                                        <CustomButton value={2} onClick={(e)=>setSubmitBtnVal(e.target.value)} id={'add_more'} type={"submit"} variant="outlined" title={"Add More Players"} />
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </>
    )
}

export default CreatePlayers